import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { Card, Form } from "react-bootstrap";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import { eventRef, payoutRef, usersRef } from "../../firebase/FirebaseConfig";
import useDetails from "../../hooks/useDetails";
import { constant } from "../../utils/constants";
import { calculatePayoutAmount, setTimeFromString } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";

const AddPayOut = () => {
  const details = useDetails();

  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      clientId: "",
      eventId: "",
      employeeId: "",
      shiftDate: new Date(),
      checkIn: "",
      checkOut: "",
      note: "",
    },
    validationSchema: yup.object().shape({
      clientId: yup.string().required().label("Client"),
      eventId: yup.string().required().label("Shift Time"),
      checkIn: yup.string().required().label("Check In"),
      checkOut: yup
        .string()
        .required()
        .label("Check Out")
        .test(
          "is-greater-than-startTime",
          "Check out time should be later than check in time",
          function (value) {
            const { shiftDate, checkIn } = this.parent;
            const start = new Date(shiftDate);
            const end = new Date(shiftDate);
            setTimeFromString(start, checkIn);
            setTimeFromString(end, value);
            return end > start;
          }
        ),    
    }),
    onSubmit: async (values) => {
      let body = {
        ...values,
        amount: calculatePayoutAmount(
          values?.checkIn,
          values?.checkOut,
          details?.hourlyRate
        ),
        createdAt: Timestamp.now(),
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        await addDoc(payoutRef, body);
        return true;
      } catch (err) {
        console.log("err", err);
        throw err;
      }
    },
    onSuccess: () => {
      navigate("../payout");
    },
  });

  const { data: clientList } = useQuery({
    queryKey: ["client-list"],
    queryFn: async () => {
      try {
        let q = query(
          usersRef,
          where("role", "==", constant.ROLE.CLIENT),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);

        const clients = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return clients;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const { data: eventList } = useQuery({
    queryKey: ["event-list", values?.clientId],
    queryFn: async () => {
      try {
        const userQuery = query(usersRef, where("uid", "==", details?.uid));
        const userSnapshot = await getDocs(userQuery);

        const employeeDoc = userSnapshot.docs.find((doc) => !!doc);
        if (!employeeDoc) {
          toastAlert("error", "User not found");
          return [];
        }
        setFieldValue("employeeId", employeeDoc.id);
        const employeeId = employeeDoc.id;
        const currentDate = new Date();

        if (!values?.clientId) {
          return [];
        }

        const eventQuery = query(
          eventRef,
          where("employeeId", "==", employeeId),
          where("clientId", "==", values.clientId),
          where("endTime", "<=", currentDate),
          orderBy("endTime", "desc")
        );

        const eventSnapshot = await getDocs(eventQuery);
        const eventsData = eventSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const payoutQuery = query(
          payoutRef,
          where("employeeId", "==", employeeId),
          where("clientId", "==", values.clientId)
        );
        const payoutSnapshot = await getDocs(payoutQuery);
        const payoutEventIds = payoutSnapshot.docs.map(
          (doc) => doc.data().eventId
        );

        const filteredEvents = eventsData.filter(
          (event) => !payoutEventIds.includes(event.id)
        );

        return filteredEvents;
      } catch (err) {
        console.error("Error fetching event list:", err);
        throw err;
      }
    },
  });

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>Add Payout</NavigateBack>
          <Card border="light" bg="light" className="p-4 w-50 mx-auto">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox ">
                <div className="row ">
                  <div className="col-md-12 mb-3 ">
                    <div className="form-group">
                      <label className="labelTxt">
                        Client <span className="text-danger">*</span>
                      </label>
                      <select
                        name="clientId"
                        className="inputBox"
                        value={values?.clientId}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("eventId", "");
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">Select</option>
                        {clientList &&
                          clientList?.map((i, index) => (
                            <option
                              value={i.id}
                              key={index}
                            >{`${i?.firstName} ${i?.lastName}`}</option>
                          ))}
                      </select>

                      <small className="text-danger requiredTxt">
                        {touched.clientId && errors.clientId}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <div className="form-group">
                      <label className="labelTxt">
                        Shift Time <span className="text-danger">*</span>
                      </label>
                      <select
                        name="eventId"
                        className="inputBox"
                        value={values?.eventId}
                        onChange={(e) => {
                          handleChange(e);
                          let selectedEvent = eventList?.find(
                            (item) => item?.id == e.target.value
                          );
                          if (!!selectedEvent)
                            setFieldValue(
                              "shiftDate",
                              new Date(selectedEvent?.startTime?.toDate())
                            );
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">Select</option>
                        {eventList &&
                          eventList?.map((i, index) => (
                            <option value={i?.id} key={index}>{`${moment(
                              i?.startTime?.toDate()
                            ).format("ll")} (${moment(
                              i?.startTime?.toDate()
                            ).format("hh:mm a")} - ${moment(
                              i?.endTime?.toDate()
                            ).format("hh:mm a")})`}</option>
                          ))}
                      </select>

                      <small className="text-danger requiredTxt">
                        {touched.eventId && errors.eventId}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-In
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="checkIn"
                        className="timePicker inputBox"
                        value={values?.checkIn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.checkIn && errors.checkIn}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-Out
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="checkOut"
                        className="timePicker inputBox"
                        value={values?.checkOut}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.checkOut && errors.checkOut}
                      </small>
                    </div>
                  </div>
                  
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Note</label>
                      <textarea
                        rows={3}
                        name="note"
                        className="inputBox"
                        value={values?.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.note && errors.note}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <p className="text-danger requiredTxt">
                      <b>Note:</b> Once you've submitted the payout details,
                      they can't be changed. Make sure to review them carefully
                      before submitting!
                    </p>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  Add
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
      </section>
      {mutation.isPending && <Loader />}
    </>
  );
};

export default AddPayOut;
