import { useMutation, useQuery } from "@tanstack/react-query";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { useFormik } from "formik";
import React from "react";
import { Card, Form } from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import { getErrorMessage } from "../../firebase/errors";
import { auth, db, usersRef } from "../../firebase/FirebaseConfig";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import { generatePassword } from "../../utils/function";

const AddEmployee = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");

  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setValues,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      hourlyRate: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      role: constant.ROLE.EMPLOYEE,
      password: "",
      showPassword: false,
    },
    validationSchema: yup.object().shape({
      firstName: yup
        .string()
        .required()
        .label("First name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "First name should contain letters only"
        ),
      lastName: yup
        .string()
        .label("Last name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Last name should contain letters only"
        ),
      email: yup.string().required().email().label("Email").trim(),
      phoneNumber: yup
        .string()
        .min(5)
        .label("Phone Number")
        .test("phone-validate", "Invalid phone number", function (value) {
          if (value?.length > 6) {
            return isValidPhoneNumber(String(value));
          } else {
            return true;
          }
        }),
      hourlyRate: yup
        .number()
        .required()
        .positive()
        .label("Hourly Rate")
        .typeError("Invalid number input"),
      address: yup.string().required().label("Address").trim().min(2),
      city: yup.string().required().label("City").trim().min(2),
      state: yup.string().required().label("State").trim().min(2),
      country: yup.string().required().label("Country").trim().min(2),
      // password: yup.string().when(["$id"], {
      //   is: () => !id,
      //   then: () =>
      //     yup
      //       .string()
      //       .required()
      //       .label("Password")
      //       .matches(
      //         /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
      //         "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character."
      //       )
      //       .trim(),
      // }),
      zipCode: yup
        .string()
        .required()
        .label("Zip Code")
        .trim()
        .min(2)
        .matches(/^[a-zA-Z0-9]*$/, "Invalid zip code"),
    }),
    onSubmit: (values) => {
      let body = {
        ...values,
        createdAt: Timestamp.now(),
      };

      if (!id || id == "") {
        body.password = generatePassword(body)
        if (!body.password || body.password == "") toastAlert("error", "Error while creating password")
      }

      mutation.mutate(body);

    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        let user;
        if (!id) {
          user = await createUserWithEmailAndPassword(
            auth,
            body.email,
            body.password
          );
          body.uid = user?.user?.uid;
        }

        delete body.password;
        delete body.showPassword;

        await (!!id
          ? setDoc(doc(db, constant.COLLECTIONS.USERS, id), body, {
              merge: true,
            })
          : addDoc(usersRef, body));
        return true;
      } catch (error) {
        console.log("error", error);
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: () => {
      navigate("../employee");
    },
  });

  useQuery({
    queryKey: ["employee-details", id],
    queryFn: async () => {
      if (!id) {
        return true;
      }

      try {
        const userDoc = await getDoc(doc(db, constant.COLLECTIONS.USERS, id));

        if (userDoc.exists()) {
          const data = userDoc.data();
          setValues({
            ...values,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            address: data?.address,
            city: data?.city,
            state: data?.state,
            country: data?.country,
            zipCode: data?.zipCode,
            hourlyRate: data?.hourlyRate,
          });
        } else {
          toastAlert("error", "User does not exist");
        }
      } catch (error) {
        console.log("error", error);
        toastAlert("error", "An error occurred while fetching user data");
      }

      return true;
    },
  });

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>{!!id ? "Edit Employee" : "Add Employee"}</NavigateBack>
          <Card border="light" bg="light" className="p-4">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.firstName && errors?.firstName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Last Name</label>
                      <input
                        className="inputBox"
                        type="text"
                        name="lastName"
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Last Name"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.lastName && errors?.lastName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt" htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values?.email}
                        disabled={!!id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.email && errors?.email}
                      </small>
                    </div>
                  </div>
                  {/*                   
                {!id && (
                    <div className="col-md-6 mb-3">
                      <div className="form-group position-relative">
                        <label className="labelTxt" htmlFor="password">
                          Password <span className="text-danger">*</span>
                        </label>
                        <input
                          className="inputBox"
                          type={values?.showPassword ? "text" : "password"}
                          id="password"
                          placeholder="Enter Password"
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="current-password"
                        />
                        <div
                          className="eye-icon"
                          onClick={() =>
                            setFieldValue("showPassword", !values?.showPassword)
                          }
                        >
                          {values?.showPassword ? (
                            <FaRegEyeSlash />
                          ) : (
                            <FaRegEye />
                          )}
                        </div>
                      </div>
                      <small className="text-danger requiredTxt">
                        {touched?.password && errors?.password}
                      </small>
                    </div>
                  )} */}

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Contact No <span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        defaultCountry={constant.DEFAULT_COUNTRY}
                        value={values?.phoneNumber}
                        onChange={(phone) =>
                          setFieldValue("phoneNumber", phone)
                        }
                        onBlur={() => setFieldTouched("phoneNumber", true)}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.phoneNumber && errors?.phoneNumber}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Hourly Rate($)<span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Hourly Rate"
                        name="hourlyRate"
                        value={values?.hourlyRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.hourlyRate && errors?.hourlyRate}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Address <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={values?.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.address && errors?.address}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.city && errors?.city}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.state && errors?.state}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Country <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Country"
                        name="country"
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.country && errors?.country}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Zipcode <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        placeholder="Enter Zipcode"
                        name="zipCode"
                        value={values?.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={6}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.zipCode && errors?.zipCode}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <Link className="cancleBtn me-3" role="button" to={-1}>
                  Cancel
                </Link>
                <button className="addBtn " type="submit">
                  {!!id ? "Update" : "Add"}
                </button>
              </div>
            </Form>
          </Card>
        </div>
      </section>
      {mutation.isPending && <Loader />}
    </>
  );
};

export default AddEmployee;
