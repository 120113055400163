import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";
import { constant } from "../utils/constants.js";

const firebaseConfig = {
  apiKey: "AIzaSyC_rzuvqp7y6IvgFeojHlY3Dp8zl5b0ExQ",
  authDomain: "ai-scheduling-2586d.firebaseapp.com",
  projectId: "ai-scheduling-2586d",
  storageBucket: "ai-scheduling-2586d.appspot.com",
  messagingSenderId: "640120522066",
  appId: "1:640120522066:web:ba78263b76387a19cc668b"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const usersRef = collection(db, constant.COLLECTIONS.USERS);
const eventRef = collection(db, constant.COLLECTIONS.EVENTS);
const payoutRef = collection(db, constant.COLLECTIONS.PAYOUT);
const leaveRef = collection(db, constant.COLLECTIONS.LEAVES);
const projectId = firebaseConfig.projectId

export { auth, db, usersRef, eventRef, payoutRef,leaveRef, projectId };
