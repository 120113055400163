import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <div className="not-found">
        <h1 className="mainhead mb-0">404 Not Found</h1>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="addBtn btn-theme mt-4"
        >
          Go to Home
        </button>
      </div>
    </Container>
  );
};

export default NotFound;
