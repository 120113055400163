import { useMutation, useQuery } from "@tanstack/react-query";
import {
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCamera } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { db, usersRef } from "../../firebase/FirebaseConfig";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { login } from "../../redux/features/authSlice";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
import { getDaysString } from "../../utils/function";

const Profile = () => {
  const storage = getStorage();
  const details = useDetails();
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const role = useRole();
  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      profileImg: "",
      newPicked: "",
      workingDays: [],
      workingHours: {
        startTime: "",
        endTime: "",
      },
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required().label("First Name").trim(),
      newPicked: yup.mixed().when(([newPicked], schema) => {
        if (newPicked) {
          return yup
            .mixed()
            .test("type", "Please choose png, jpg and jpeg", function (value) {
              return (
                value &&
                (value.type === "image/jpg" ||
                  value.type === "image/png" ||
                  value.type === "image/jpeg")
              );
            });
        }
        return schema;
      }),
      workingDays: yup.array().when("role", {
        is: () => role == constant.ROLE.EMPLOYEE,
        then: () => yup.array().min(1).label("Working Days")
      }),
      workingHours: yup.object().when("role", {
        is: () => role == constant.ROLE.EMPLOYEE,
        then: () => yup.object().shape({
          startTime: yup.string().required().label("Start time"),
          endTime: yup
            .string()
            .required()
            .label("End time")
            .test(
              "is-greater-than-startTime",
              "End time should be later than start time",
              function (value) {
                const { startTime } = this.parent;
                const start = new Date(startTime);
                const end = new Date(value);
                return end > start;
              }
            ),
        }),
      })
    }),
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  const { refetch } = useQuery({
    queryKey: ["userProfile", details?.uid],
    queryFn: async () => {
      try {
        const q = query(usersRef, where("uid", "==", details?.uid));
        const querySnapshot = await getDocs(q);
        const currentUserDoc = querySnapshot.docs.find((doc) => !!doc);

        if (!currentUserDoc) {
          toastAlert("error", "User does not exist");
          return {};
        }
        const userData = currentUserDoc.data();
        setValues({
          ...values,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email,
          profileImg: userData?.profileImg,
          workingDays: userData?.workingDays?.sort() || [],
          workingHours: {
            startTime: userData?.workingHours?.startTime
              ? new Date(moment(userData?.workingHours?.startTime, "HH:mm"))
              : "",
            endTime: userData?.workingHours?.endTime
              ? new Date(moment(userData?.workingHours?.endTime, "HH:mm"))
              : "",
          },
        });
        setId(currentUserDoc?.id);
        return userData;
      } catch (err) {
        console.log("err", err);
        return {};
      }
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        if (body?.newPicked) {
          const storageRef = ref(
            storage,
            `images/${uuidv4()}-${body.newPicked.name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, body.newPicked);

          await new Promise((resolve, reject) => {
            uploadTask.on("state_changed", null, reject, async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              body.profileImg = downloadURL;
              resolve();
            });
          });
        }

        const startTime = moment(body?.workingHours?.startTime, 'HH:mm')
        const endTime = moment(body?.workingHours?.endTime, 'HH:mm')
        const difference = endTime.diff(startTime, 'minutes');

        let formData = {
          ...body,
          workingHours: {
            startTime: startTime?.format("HH:mm"),
            endTime: endTime?.format("HH:mm"),
            duration: difference
          },
        };

        delete formData?.newPicked;
        if (!formData?.profileImg) delete formData?.profileImg;

        await setDoc(doc(db, constant.COLLECTIONS.USERS, id), formData, {
          merge: true,
        });
        toastAlert("success", "Profile updated successfully");
      } catch (err) {
        console.error("Failed to update profile:", err);
        toastAlert("error", "Failed to update profile");
      }
    },
    onSuccess: async () => {
      const q = query(usersRef, where("uid", "==", details?.uid));
      const querySnapshot = await getDocs(q);
      const currentUserDoc = querySnapshot.docs.find((doc) => !!doc);
      const userData = currentUserDoc.data();
      if (userData?.createdAt instanceof Timestamp) {
        userData.createdAt = userData.createdAt.toDate().toISOString();
      }

      dispatch(login(userData));
      setIsEditing(false);
      refetch();
    },
  });

  return (
    <div className="main-content">
      <div className="userData">
        <div className="d-flex justify-content-between">
          <div className="userLeft">
            <div className="userImg position-relative">
              <img
                src={
                  values?.newPicked
                    ? URL.createObjectURL(values?.newPicked)
                    : values?.profileImg
                      ? values?.profileImg
                      : images.profile
                }
                alt="profile"
                width={250}
                className="profileUploadImg"
              />
              {isEditing && (
                <label htmlFor="profileImage" className="cameraUpload">
                  <FaCamera size={20} />
                </label>
              )}

              <input
                type="file"
                id="profileImage"
                className="d-none"
                accept="image/*"
                onChange={(e) => setFieldValue("newPicked", e.target.files[0])}
              />
              <small className="text-danger ms-1">
                {touched.newPicked && errors.newPicked}
              </small>
            </div>
            <div className="userInfo p-3">
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">First Name</h6>
                {isEditing ? (
                  <input
                    type="text"
                    name="firstName"
                    value={values?.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                ) : (
                  <p className="mb-0">{`${values?.firstName}`}</p>
                )}

                <small className="text-danger requiredTxt">
                  {touched?.firstName && errors?.firstName}
                </small>
              </div>
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">Last Name</h6>
                {isEditing ? (
                  <input
                    type="text"
                    name="lastName"
                    value={values?.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                ) : (
                  <p className="mb-0">{values?.lastName}</p>
                )}
              </div>
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">Email</h6>
                {isEditing ? (
                  <input
                    type="email"
                    name="email"
                    value={values?.email}
                    readOnly
                    className="form-control"
                  />
                ) : (
                  <p className="mb-0">{values?.email}</p>
                )}
              </div>
            </div>
          </div>
          <div className="userRight">
            {!isEditing && (
              <button
                onClick={() => {
                  setIsEditing(true);
                }}
                className="greenBtn"
              >
                Edit Profile
              </button>
            )}
          </div>
        </div>
        {role == constant.ROLE.EMPLOYEE && (
          <div className="userWork ps-4">
            <div className="workingDays">
              <h5 className="prefferedDays">Preffered Working Days</h5>
            </div>

            <div className="workDayCheckbox">
              {isEditing ? (
                <>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="Sun"
                      name="workingDays"
                      value={0}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("0")}
                    />
                    <label className="daysLabel" htmlFor="Sun">
                      Sunday
                    </label>
                  </div>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="mon"
                      name="workingDays"
                      value={1}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("1")}
                    />
                    <label className="daysLabel" htmlFor="mon">
                      Monday
                    </label>
                  </div>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="Tue"
                      name="workingDays"
                      value={2}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("2")}
                    />
                    <label className="daysLabel" htmlFor="Tue">
                      Tuesday
                    </label>
                  </div>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="Wed"
                      name="workingDays"
                      value={3}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("3")}
                    />
                    <label className="daysLabel" htmlFor="Wed">
                      Wednesday
                    </label>
                  </div>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="Thu"
                      name="workingDays"
                      value={4}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("4")}
                    />
                    <label className="daysLabel" htmlFor="Thu">
                      Thursday
                    </label>
                  </div>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="Fri"
                      name="workingDays"
                      value={5}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("5")}
                    />
                    <label className="daysLabel" htmlFor="Fri">
                      Friday
                    </label>
                  </div>
                  <div className="days">
                    <input
                      type="checkbox"
                      id="Sat"
                      name="workingDays"
                      value={6}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("workingDays", true)}
                      checked={values?.workingDays?.includes("6")}
                    />
                    <label className="daysLabel" htmlFor="Sat">
                      Saturday
                    </label>
                  </div>
                </>
              ) : (
                <p className="mb-0">{getDaysString(values?.workingDays)}</p>
              )}
            </div>
            <small className="text-danger requiredTxt">
              {touched.workingDays && errors.workingDays}
            </small>
            <div className="row mt-4">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="labelTxt">
                    Start Time <span className="text-danger">*</span>
                  </label>
                  {isEditing ? (
                    <>
                      <DatePicker
                        selected={values?.workingHours?.startTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="inputBox"
                        onBlur={() =>
                          setFieldTouched("workingHours.startTime", true)
                        }
                        onChange={(date) =>
                          setFieldValue("workingHours.startTime", date)
                        }
                      />
                      <small className="text-danger requiredTxt">
                        {touched.workingHours?.startTime &&
                          errors.workingHours?.startTime}
                      </small>
                    </>
                  ) : (
                    <p className="mb-0">
                      {values?.workingHours?.startTime ? moment(values?.workingHours?.startTime)?.format("LT") : "Not set"}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="labelTxt">
                    End Time <span className="text-danger">*</span>
                  </label>

                  {isEditing ? (
                    <>
                      <DatePicker
                        selected={values?.workingHours?.endTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="inputBox"
                        onBlur={() =>
                          setFieldTouched("workingHours.endTime", true)
                        }
                        onChange={(date) =>
                          setFieldValue("workingHours.endTime", date)
                        }
                      />

                      <small className="text-danger requiredTxt">
                        {touched.workingHours?.endTime &&
                          errors.workingHours?.endTime}
                      </small>
                    </>
                  ) : (
                    <p className="mb-0">
                      {values?.workingHours?.endTime ? moment(values?.workingHours?.endTime)?.format("LT") : "Not set"}
                    </p>
                  )}
                </div>
              </div>
            </div>


          </div>
        )}
        {isEditing && (
          <div className="staffBtn mt-4">
            <button
              className="addBtn me-3"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              className="cancleBtn"
              type="button"
              onClick={() => {
                setIsEditing(false);
                refetch();
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      {mutation?.isPending && <Loader />}
    </div>
  );
};

export default Profile;
