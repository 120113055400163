import React from "react";
import { useNavigate } from "react-router-dom";
import * as images from "../assets/image";

const NavigateBack = ({ children, path, handleClick }) => {
  const navigate = useNavigate();
  return (
    <div
      className="addHeader mb-3"
      role="button"
      key={Math.random()}
      onClick={() => (handleClick ? handleClick() : navigate(path ? path : -1))}
    >
      <img src={images.leftArrow} alt="Image" className="me-3" />
      <span className="heading20">{children}</span>
    </div>
  );
};

export default NavigateBack;
