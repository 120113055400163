import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { db } from "../../firebase/FirebaseConfig";
import { collection } from "firebase/firestore";

const Service = () => {
  const serviceRef = collection(db, "services");
  const [search, setSearch] = useState("");

  const { data, refetch } = useQuery({
    queryKey: ["service-list"],
    queryFn: async () => {
      try {
        let q = query(serviceRef, orderBy("createdAt", "desc"));

        const querySnapshot = await getDocs(q);

        const services = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (search) {
          return services.filter(
            (i) =>
              i?.title?.includes(search) || i?.description?.includes(search)
          );
        }
        return services;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          deleteMutation.mutate(id);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteDoc(doc(db, "services", id));
    },
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "Service has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  return (
    <>
      <section className="main-content">
        <div className="commonSearchBar mt-3">
          <h4>Services</h4>
          <div className="endContent">
            <div className="clientSearch">
              <img
                src={images.Search}
                alt="searchImg"
                className="clientSearchIcon"
              />
              <input
                type="text"
                placeholder="Type and press enter to search."
                className="clientSearchInput"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) =>
                  e.key == "Enter" && search.trim() !== "" && refetch()
                }
                onKeyUp={(e) =>
                  e.target.value == "" && e.key == "Backspace" && refetch()
                }
              />
            </div>
            <Link className="greenBtn" to="../add-service">
              Add Services
            </Link>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <table className="commonTable">
              <thead className="tableHead">
                <tr>
                  <th className="heading14 tableHeading">S. No</th>
                  <th className="heading14 tableHeading">Title</th>
                  <th className="heading14 tableHeading desHead">
                    Description
                  </th>
                  <th className="heading14 tableHeading">Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={item.id}>
                      <td className="heading14 tableHeading">{index + 1}</td>
                      <td className="heading14 tableHeading">{item?.title}</td>

                      <td className="heading14 tableHeading">
                        {item?.description}
                      </td>
                      <td className="heading14 tableHeading">
                        <Link to={`../add-service?id=${item?.id}`}>
                          <img
                            src={images.edit}
                            className="me-2 editImg"
                            alt="img"
                            role="button"
                          />
                        </Link>

                        <img
                          src={images.deleteImg}
                          className="ms-2 deleteImg"
                          alt="delete"
                          role="button"
                          onClick={() => handleDelete(item.id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={4}>
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {deleteMutation.isLoading && <Loader />}
    </>
  );
};

export default Service;
