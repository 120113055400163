import { useIsFetching } from "@tanstack/react-query";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes } from "react-router";
import Loader from "./common/loader/Loader";
import NotFound from "./common/notFound/NotFound";
import ScrollTop from "./common/ScrollTop";
import ChangePassword from "./pages/auth/ChangePassword";
import Login from "./pages/auth/Login";
import Profile from "./pages/auth/Profile";
import AddPayOut from "./pages/payout/AddPayOut";
import PayOut from "./pages/payout/PayOut";
import Shifts from "./pages/shifts/Shifts";
import AddTimeOff from "./pages/time-off/AddTimeOff";
import TimeOff from "./pages/time-off/TimeOff";
import AdminRoutes from "./Routes/AdminRoutes";
import "./styles/auth.css";
import "./styles/custom.css";
import "./styles/responsive.css";
import { AdminAuth, PublicAuth, UserAuth } from "./utils/ProtectedRoute";


function App() {
  const isFetching = useIsFetching();
  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="*" element={<NotFound />} />

        {/*********************Admin Routes*********************/}
        <Route path="*" element={<AdminAuth />}>
          <Route path="admin/*" element={<AdminRoutes />} />
        </Route>

        {/*********************User Routes*********************/}
        <Route path="*" element={<UserAuth />}>
          <Route path="profile" element={<Profile />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="shifts" element={<Shifts />} />
          <Route path="payout" element={<PayOut />} />
          <Route path="add-payout" element={<AddPayOut />} />
          <Route path="time-off" element={<TimeOff />} />
          <Route path="add-time-off" element={<AddTimeOff />} />
        </Route>

        {/*********************All Public Routes*********************/}
        <Route path="*" element={<PublicAuth />}>
          <Route path="" element={<Login />} />
        </Route>
      </Routes>
      {!!isFetching && <Loader />}
    </>
  );
}

export default App;
