import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import { FaCheck, FaTrash, FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../common/loader/Loader";
import { db, leaveRef, usersRef } from "../../firebase/FirebaseConfig";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";

const TimeOff = () => {
  const details = useDetails();
  const role = useRole();

  const { data, refetch } = useQuery({
    queryKey: ["leave-list"],
    queryFn: async () => {
      return new Promise(async (resolve, reject) => {
        try {
          let currentUserId;

          if (role === constant.ROLE.EMPLOYEE) {
            const userQuery = query(usersRef, where("uid", "==", details?.uid));
            const userSnapshot = await getDocs(userQuery);

            const currentUserDoc = userSnapshot.docs.find((doc) => !!doc);
            if (!currentUserDoc) {
              toastAlert("error", "User not found");
              return [];
            }

            currentUserId = currentUserDoc.id;
          }

          const leaveQuery =
            role === constant.ROLE.EMPLOYEE
              ? query(leaveRef, where("employeeId", "==", currentUserId))
              : query(leaveRef);
          const leaveSnapshot = await getDocs(leaveQuery);

          const leaveData = leaveSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const promises = leaveData?.map(async (leave) => {
            const employeeDoc = await getDoc(
              doc(db, constant.COLLECTIONS.USERS, leave.employeeId)
            );

            return {
              ...leave,
              employee: employeeDoc.data(),
            };
          });

          const leaves = await Promise.all(promises);
          resolve(leaves);
        } catch (err) {
          console.log("error", err);
          reject(err);
        }
      });
    },
  });

  const handleLeave = async (id, status) => {
    try {
      await setDoc(
        doc(db, constant.COLLECTIONS.LEAVES, id),
        { status: status },
        {
          merge: true,
        }
      );
      refetch();
      toastAlert(
        "success",
        status == constant.LEAVE_STATUS.APPROVED
          ? "Leave approved successfully"
          : "Leave rejected successfully"
      );
    } catch (err) {
      toastAlert("error", err);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          deleteMutation.mutate(id);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteDoc(doc(db, constant.COLLECTIONS.LEAVES, id));
    },
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "Leave request has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Time Off</h4>
        <div className="endContent">
          {role !== constant.ROLE.CLIENT && (
            <Link className="greenBtn" to="../add-time-off">
              Add Time Off
            </Link>
          )}
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    <th className="heading14 tableHeading">S. No</th>
                    {role == constant.ROLE.ADMIN && (
                      <th className="heading14 tableHeading">Employee</th>
                    )}

                    <th className="heading14 tableHeading">Date</th>
                    <th className="heading14 tableHeading">Time</th>
                    <th className="heading14 tableHeading">Reason</th>
                    <th className="heading14 tableHeading">Status</th>
                    <th className="heading14 tableHeading">Created At</th>
                    <th className="heading14 tableHeading">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.id}>
                        <td className="heading14 tableHeading">{index + 1}</td>
                        {role == constant.ROLE.ADMIN && (
                          <td className="heading14 tableHeading">
                            {`${item?.employee?.firstName} ${item?.employee?.lastName}`}
                          </td>
                        )}

                        <td className="heading14 tableHeading">
                          {moment(item?.date?.toDate()).format("ll")}
                        </td>
                        <td className="heading14 tableHeading">
                          {item?.fullDay
                            ? "Full Day Off"
                            : `${moment(item?.startDateTime?.toDate()).format(
                                "LT"
                              )} - ${moment(item?.endDateTime?.toDate()).format(
                                "LT"
                              )}`}
                        </td>
                        <td className="heading14 tableHeading">
                          {item.reason}
                        </td>
                        <td className="heading14 tableHeading">
                          <Badge
                            bg={
                              item.status == constant.LEAVE_STATUS.PENDING
                                ? "warning"
                                : item?.status == constant.LEAVE_STATUS.APPROVED
                                ? "success"
                                : "danger"
                            }
                          >
                            {item.status == constant.LEAVE_STATUS.PENDING
                              ? "Pending"
                              : item?.status == constant.LEAVE_STATUS.APPROVED
                              ? "Approved"
                              : item?.status == constant.LEAVE_STATUS.REJECTED
                              ? "Rejected"
                              : "Cancelled"}
                          </Badge>
                        </td>
                        <td className="heading14 tableHeading">
                          {moment(item.createdAt?.toDate()).format("lll")}
                        </td>
                        <td className="heading14 tableHeading">
                          {role == constant.ROLE.ADMIN ? (
                            <>
                              <FaCheck
                                size={20}
                                role="button"
                                className="me-2"
                                onClick={() => {
                                  if (item.status !== constant.LEAVE_STATUS.APPROVED) handleLeave(item?.id, constant.LEAVE_STATUS.APPROVED)
                                }}
                              />
                              <FaXmark
                                size={20}
                                role="button"
                                onClick={() => {
                                  if (item.status !== constant.LEAVE_STATUS.REJECTED) handleLeave(item?.id, constant.LEAVE_STATUS.REJECTED)
                                }
                                }
                              />
                            </>
                          ) : (
                            item?.status == constant.LEAVE_STATUS.PENDING && (
                              <FaTrash
                                size={20}
                                role="button"
                                onClick={() => handleDelete(item?.id)}
                              />
                            )
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {deleteMutation?.isPending && <Loader />}
    </section>
  );
};

export default TimeOff;
