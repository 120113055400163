import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, usersRef } from "../firebase/FirebaseConfig";
import { toastAlert } from "./SweetAlert";
import { constant } from "./constants";
import Swal from "sweetalert2";
import moment from "moment";

export function setTimeFromString(date, timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

/**
 *
 * @param {*} checkinTime
 * @param {*} checkoutTime
 * @param {*} hourlyRate
 * @returns
 */

export const calculatePayoutAmount = (
  checkinTime,
  checkoutTime,
  hourlyRate
) => {
  const [checkinHours, checkinMinutes] = checkinTime.split(":").map(Number);
  const [checkoutHours, checkoutMinutes] = checkoutTime.split(":").map(Number);

  const today = new Date();
  const checkin = new Date(today.setHours(checkinHours, checkinMinutes, 0, 0));
  let checkout = new Date(today.setHours(checkoutHours, checkoutMinutes, 0, 0));

  if (checkout < checkin) {
    checkout.setDate(checkout.getDate() + 1);
  }

  const timeDifference = checkout - checkin;

  if (timeDifference <= 0) {
    toastAlert("error", "Invalid check-in or check-out times");
    return 0; 
  }

  const hoursWorked = timeDifference / (1000 * 60 * 60);
  const payoutAmount = hoursWorked * hourlyRate;

  return parseFloat(payoutAmount.toFixed(2));
};




// Helper function to fetch client details
export const fetchClientDetails = async (clientId) => {
  if (!clientId) return null;
  try {
    const clientDoc = await getDoc(doc(usersRef, clientId));
    return clientDoc.exists() ? clientDoc.data() : null;
  } catch (error) {
    console.error("Error fetching client details: ", error);
    return null;
  }
};




// Helper function to fetch employee details
export const fetchEmployeeDetails = async (employeeId) => {
  if (!employeeId) return null;
  try {
    const employeeDoc = await getDoc(doc(usersRef, employeeId));
    return employeeDoc.exists() ? employeeDoc.data() : null;
  } catch (error) {
    console.error("Error fetching employee details: ", error);
    return null;
  }
};



export const getDaysString = (dayNumbers = []) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return dayNumbers.map((dayNumber) => daysOfWeek[dayNumber]).join(", ");
};



export const checkConflicts = async ({
  employeeId,
  startTime,
  endTime,
  eventId,
}) => {
  let conflictFound = false;
  let pendingLeaveFound = false;
  let conflictDate = null;

  const eventQuery = query(
    collection(db, constant.COLLECTIONS.EVENTS),
    where("employeeId", "==", employeeId),
    where("startTime", "<", endTime),
    where("endTime", ">", startTime), 
    where("startTime", ">=", new Date())
  );

  const leaveQuery = query(
    collection(db, constant.COLLECTIONS.LEAVES),
    where("employeeId", "==", employeeId),
    where("startDateTime", "<", endTime),
    where("endDateTime", ">", startTime),
    where("startDateTime", ">=", new Date())
  );

  try {
    let eventSnapshot = await getDocs(
      eventId
        ? query(eventQuery, where(documentId(), "!=", eventId))
        : eventQuery
    );

    if (!eventSnapshot.empty) {
      conflictFound = true;

      conflictDate = moment(
        eventSnapshot.docs[0]?.data()?.startTime?.toDate()
      ).format("lll");
    }
  } catch (error) {
    console.error("Error fetching events:", error);
    throw new Error("Error checking event conflicts");
  }

  try {
    const leaveSnapshot = await getDocs(leaveQuery);
    leaveSnapshot.forEach((doc) => {
      const leaveData = doc.data();
      if (leaveData.status === constant.LEAVE_STATUS.APPROVED) {
        conflictFound = true;
        conflictDate = moment(leaveData.startDateTime.toDate()).format("lll");
      }
      if (leaveData.status === constant.LEAVE_STATUS.PENDING) {
        pendingLeaveFound = true;
        conflictDate = moment(leaveData.startDateTime.toDate()).format("lll");
      }
    });
  } catch (error) {
    console.error("Error fetching leaves:", error);
    throw new Error("Error checking leave conflicts");
  }

  if (conflictFound) {
    Swal.fire({
      title: "Conflict Found!",
      text: `Employee has another event or approved leave on ${conflictDate}.`,
      icon: "warning",
      confirmButtonColor: "#0d1227",
    });
  } else if (pendingLeaveFound) {
    Swal.fire({
      title: "Pending Leave Found!",
      text: `Employee has a pending leave request on ${conflictDate}. Please approve or reject the leave before scheduling the event.`,
      icon: "warning",
      confirmButtonColor: "#0d1227",
    });
  }

  return { conflictFound, pendingLeaveFound };
};

export const getRecurringSlots = async (startDate, numberOfDays, reccuredOn = []) => {
  const startDateObject = new Date(startDate) // Date to start from
  const recurringSlots = []

  for (let day = 0; day <= numberOfDays; day++) {
    const nextDate = new Date(startDateObject);
    nextDate.setDate(nextDate.getDate() + day);
    const dayOfWeek = nextDate.getDay();
    if (reccuredOn.includes(`${dayOfWeek}`)) recurringSlots.push({ date: nextDate, weekDay: dayOfWeek })
  }

  return recurringSlots
}

export const getMinuteDifference = (start, end) => {
  const startTime = moment(start, 'HH:mm')
  const endTime = moment(end, 'HH:mm')
  return endTime.diff(startTime, 'minutes') || 0;
}

export const fetchEmployees = async (availabity) => {
  try {
    let whercond = [where("role", "==", constant.ROLE.EMPLOYEE),]

    if (availabity && availabity.length) {
      whercond = [
        ...whercond,
        where('workingDays', 'array-contains-any', availabity) // for finding employee availabe on given days
      ]
    }
    const userQuery = query(usersRef, where("role", "==", constant.ROLE.EMPLOYEE), ...whercond);
    const querySnapshot = await getDocs(userQuery);
    const employees = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return employees
  } catch (error) {
    console.error("Error fetching client details: ", error);
    return null;
  }
};
export const employeeTotalDuration = async (data, eventId) => {
  const { employeeId, startTime, endTime, eventDuration = 0 } = data;

  const startDateOnly = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate()); // given start date at 00:00 Time
  const endDateOnly = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate() + 1); // Next days Date at 00:00 Time

  let totalDuration = eventDuration
  const eventdurationQuery = query(
    collection(db, constant.COLLECTIONS.EVENTS),
    where("employeeId", "==", employeeId),
    where("startTime", ">=", startDateOnly),
    where("endTime", "<", endDateOnly)
  );


  const durationSnapShot = await getDocs(eventId ? query(eventdurationQuery, where(documentId(), "!=", eventId)) : eventdurationQuery)
  if (durationSnapShot?.docs?.length) {
    durationSnapShot?.docs.forEach((doc) => {
      const docData = doc.data()
      const duration = docData?.duration || 0
      totalDuration += duration
    });
  }

  console.log(`${employeeId} has total Duration of ${totalDuration} `)
  if (totalDuration > constant.MAX_EVENT_DURATION_MINUTES) return false // If totalDuration exceeds max event duration then return false 

  return true
}

export const conflictedEvent = async (data) => {
  const { employeeId, startTime, endTime, eventId, eventDuration } = data;

  let available = true

  const eventQuery = query(
    collection(db, constant.COLLECTIONS.EVENTS),
    where("employeeId", "==", employeeId),
    where("startTime", "<", endTime),
    where("endTime", ">", startTime),
    where("startTime", ">=", new Date())
  );

  const leaveQuery = query(
    collection(db, constant.COLLECTIONS.LEAVES),
    where("employeeId", "==", employeeId),
    where("startDateTime", "<", endTime),
    where("endDateTime", ">", startTime),
    where("startDateTime", ">=", new Date())
  );

  try {
    const eventSnapshot = await getDocs(
      eventId ? query(eventQuery, where(documentId(), "!=", eventId)) : eventQuery
    );

    if (!eventSnapshot.empty) return false
  } catch (error) {
    console.error("Error fetching events:", error);
  }

  try {
    const leaveSnapshot = await getDocs(leaveQuery);
    if (leaveSnapshot.docs.length) {
      leaveSnapshot.forEach((doc) => {
        const leaveData = doc.data();
        if (leaveData.status === constant.LEAVE_STATUS.APPROVED) available = false
        if (leaveData.status === constant.LEAVE_STATUS.PENDING) available = false
      });
    }
  } catch (error) {
    console.error("Error fetching leaves:", error);
  }
  return available
};


export const checkSingleAvailabilty = (employee, eventTiming) => {
  try {
    let isAvailable = true
    const momentEventStart = moment(eventTiming.startTime); // event Start time
    const momentEventTill = moment(eventTiming.endTime);
    const weekDay = momentEventStart.day()

    if (!employee?.workingDays?.includes(String(weekDay))) return false

    const employeeStartTime = moment(`${momentEventStart.format('YYYY-MM-DD')} ${employee.workingHours.startTime}`, 'YYYY-MM-DD hh:mm:ss'); // employee starting hours
    const employeeEndTime = moment(`${momentEventStart.format('YYYY-MM-DD')} ${employee.workingHours.endTime}`, 'YYYY-MM-DD hh:mm:ss'); // employee ending hours

    if (momentEventStart.isBefore(employeeStartTime)) return false;  // check if the event is stating before the emplayee availablity
    if (momentEventTill.isAfter(employeeEndTime)) return false;

    return isAvailable

  } catch (error) {
    console.log("Error >", error)
    return false
  }
}

export const generatePassword = (formData) => {
  console.log("functions@generatePassword")
  try {
    const { firstName, lastName } = formData
    if ((!firstName || firstName?.trim() == "") || (!lastName || lastName?.trim() == "")) return null;
    let password = ""
    password = `${firstName?.charAt(0)?.toUpperCase()}${lastName?.trim()?.toLowerCase()}${constant.PASSWORD_SUFFIX}`
    return password;
  } catch (error) {
    console.log("Error@generatePassword", error)
  }
}