import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import "react-international-phone/style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import { getErrorMessage } from "../../firebase/errors";
import { db } from "../../firebase/FirebaseConfig";
import { toastAlert } from "../../utils/SweetAlert";

const AddService = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const serviceRef = collection(db, "services");

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title").trim().min(2),
      description: yup.string().required().label("Description").trim().min(2),
    }),
    onSubmit: (values) => {
      let body = {
        ...values,
        createdAt: Timestamp.now(),
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        await (!!id
          ? setDoc(doc(db, "services", id), body, { merge: true })
          : addDoc(serviceRef, body));
        return true;
      } catch (error) {
        console.log("error", error);
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: () => {
      navigate("../service");
    },
  });

  useQuery({
    queryKey: ["service-details", id],
    queryFn: async () => {
      if (!id) return;

      try {
        const serviceDoc = await getDoc(doc(db, "services", id));

        if (serviceDoc.exists()) {
          const data = serviceDoc.data();
          setValues({
            title: data.title || "",
            description: data.description || "",
          });
        } else {
          toastAlert("error", "Service does not exist");
        }
      } catch (error) {
        console.log("error", error);
        toastAlert("error", "An error occurred while fetching service data");
      }
      return true;
    },
  });

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>{!!id ? "Edit Service" : "Add Service"}</NavigateBack>
          <Form onSubmit={handleSubmit}>
            <div className="clientInputBox">
              <div className="row mx-auto w-50">
                <div className="col-md-12 mb-3">
                  <div className="form-group">
                    <label className="labelTxt">Title</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Enter Title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <small className="text-danger requiredTxt">
                      {touched?.title && errors?.title}
                    </small>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="labelTxt">Description</label>
                    <textarea
                      className="inputBox"
                      placeholder="Enter Description"
                      rows={2}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <small className="text-danger requiredTxt">
                      {touched?.description && errors?.description}
                    </small>
                  </div>
                </div>
                <div className="staffBtn mt-4">
                  <Link className="cancleBtn me-3" role="button" to={-1}>
                    Cancel
                  </Link>
                  <button className="addBtn" type="submit">
                    {!!id ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
      {mutation.isPending && <Loader />}
    </>
  );
};

export default AddService;
