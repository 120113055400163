import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../firebase/FirebaseConfig";
import { isLogin, login } from "../redux/features/authSlice";

const useAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!!user) {
        dispatch(isLogin(true));
      } else {
        dispatch(isLogin(false));
        dispatch(login());
      }
    });

    return () => unsubscribe();
  }, []);

  return isLoggedIn ? isLoggedIn : false;
};

export default useAuth;
