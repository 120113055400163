import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where
} from "firebase/firestore";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import uuid4 from "uuid4";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { getErrorMessage } from "../../firebase/errors";
import { db, projectId, usersRef } from "../../firebase/FirebaseConfig";
import { constant } from "../../utils/constants";
import {
  checkConflicts,
  checkSingleAvailabilty,
  conflictedEvent,
  employeeTotalDuration,
  fetchClientDetails,
  fetchEmployeeDetails,
  getMinuteDifference,
  getRecurringSlots,
} from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";

const AddEvent = ({ show, setShow, refetch }) => {
  const dateRef = useRef();
  const endDateRef = useRef();

  const [editing, setEditing] = useState(false)
  const [authorized, setAuthorized] = useState([])

  const handleClose = () => {
    setShow(false);
    setEditing(false);
    resetForm();
    refetch();
  };
  let currentDate = new Date();
  let endDateReoccurringEvent = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0); // getMonth + 1 because new date month lies between 0 - 11 where 0 is jan and 11 is dec

  const {
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      id: "",
      employeeId: "",
      shiftDate: new Date(),
      startTime: "",
      endTime: "",
      clientId: "",
      note: "",
      sync: false,
      reoccurring: false,
      repeatOn: [],
      endsOn: endDateReoccurringEvent,
    },
    validationSchema: yup.object().shape({
      shiftDate: yup.date().required().label("Shift date"),
      startTime: yup
        .string()
        .required()
        .label("Start time")
        .test(
          "is-greater-than-current",
          "Start time should be later than the current time",
          function (value) {
            let startTime = new Date(value);
            return startTime >= new Date();
          }
        ),
      endTime: yup
        .string()
        .required()
        .label("End time")
        .test(
          "is-greater-than-startTime",
          "End time should be later than start time",
          function (value) {
            const { startTime } = this.parent;
            const start = new Date(startTime);
            const end = new Date(value);
            return end > start;
          }
        ),
      clientId: yup.string().required().label("Client"),
      repeatOn: yup.array().when("reoccurring", {
        is: "true",
        then: () => yup.array().min(1).label("Repeat on"),
      }),
      endsOn: yup.string().when("reoccurring", {
        is: "true",
        then: () => yup.string().required().label("End on").nullable().test(
          "is-greater-than-startTime",
          "End On should be later than start date",
          function (value) {
            const { shiftDate } = this.parent;
            const start = new Date(shiftDate);
            start.setHours(0, 0, 0, 0)
            const end = new Date(value);
            end.setHours(0, 0, 0, 0)
            return start < end;
          }
        ),
      }),
    }),

    onSubmit: async (values) => {
      let body = {
        employeeId: values.employeeId,
        clientId: values.clientId,
        note: values?.note,
        createdAt: Timestamp.now(),
        sync: values?.sync,
        startTime: new Date(values?.startTime),
        endTime: new Date(values?.endTime),
        reoccurring: values.reoccurring,
        isCancelled: false
      };
      if (values?.reoccurring == "true") {
        body.reoccurring = true;
        body.repeatOn = values.repeatOn;
        body.endsOn = values.endsOn;
        body.reoccurringId = uuid4();
      }

      const minuteDiff = getMinuteDifference(body?.startTime, body?.endTime) // getting time different 
      body.duration = minuteDiff
      mutation.mutate(body);
    },
  });

  const { data: employeeList } = useQuery({
    queryKey: ["employee-list"],
    queryFn: async () => {
      try {
        let q = query(
          usersRef,
          where("role", "==", constant.ROLE.EMPLOYEE),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);

        const employee = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return employee;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const { data: clientList } = useQuery({
    queryKey: ["client-list"],
    queryFn: async () => {
      try {
        let q = query(
          usersRef,
          where("role", "==", constant.ROLE.CLIENT),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);

        const clients = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return clients;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });
  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        if (!editing && body.reoccurring) { // if the event is reccuring

          const eventStartAt = new Date(body.startTime);  // start time for the event
          const eventTill = new Date(body.endsOn); // end time of the event 

          const timeDifference = eventTill - eventStartAt;
          const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));  // difference converting to days by minutes
          const minuteDiff = getMinuteDifference(eventStartAt, body.endTime) // getting time different 

          const recurredSlots = await getRecurringSlots(eventStartAt, dayDifference, body.repeatOn) // getting reccured slots
          if (recurredSlots.length && minuteDiff) { // should only runs if slots are avaialable and difference is there

            const eventRef = collection(db, constant.COLLECTIONS.EVENTS);
            for (let slots of recurredSlots) {

              const eventBody = { ...body, duration: minuteDiff }

              eventBody.startTime = slots.date

              const endDate = new Date(slots.date)
              eventBody.endTime = new Date(endDate.setHours(
                body.endTime.getHours(),
                body.endTime.getMinutes()
              ))

              await addDoc(eventRef, eventBody);

            }
          } else {
            return { success: true };
          }
        } else { // for single events
          if (values?.employeeId) {

            const selectedEmployee = employeeList.find(item => item.id == values.employeeId)
            const conflictcheck = {
              employeeId: values.employeeId,
              startTime: body.startTime,
              endTime: body.endTime,
              eventDuration: body.duration
            }
            const isAvailable = checkSingleAvailabilty(selectedEmployee, conflictcheck) // for checking the availability of the selected employee
            if (!isAvailable) {
              Swal.fire({
                title: "Conflict Found!",
                text: `Selected Employee is not available on the selected time`,
                icon: "warning",
                confirmButtonColor: "#0d1227",
              })
              return { success: false };
            }
            const haveWorkWindow = await employeeTotalDuration(conflictcheck, values?.id) // for checking if the employee is getting over booked or not 
            if (!haveWorkWindow) {
              Swal.fire({
                title: "Conflict Found!",
                text: `Employees is being over booked`,
                icon: "warning",
                confirmButtonColor: "#0d1227",
              })
              return { success: false };
            }

            const { conflictFound, pendingLeaveFound } = await checkConflicts({ // for checking is any pending leave is exist when creating new events
              employeeId: values.employeeId,
              startTime: body.startTime,
              endTime: body.endTime,
              eventId: values?.id ? values?.id : null,
            });

            if (conflictFound || pendingLeaveFound) return { success: false };
          }

          try {
            if (values?.id) {
              try {
                const googleEventId =
                  show?.event?.extendedProps?.calendarEventId;
                let client = await fetchClientDetails(values?.clientId);
                let employee = await fetchEmployeeDetails(values?.employeeId);

                const attendees = [];

                if (employee?.email) {
                  attendees.push({ email: employee.email });
                }

                if (client?.email) {
                  attendees.push({ email: client.email });
                }

                if (googleEventId) {
                  const [googleResponse, firestoreResponse] = await Promise.all(
                    [
                      fetch(
                        `https://us-central1-${projectId}.cloudfunctions.net/updateEvent`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            eventId: googleEventId,
                            event: {
                              summary: values?.employeeId
                                ? `Appointment for ${employee?.firstName} ${employee?.lastName} with ${client?.firstName} ${client?.lastName}`
                                : `Appointment with ${client?.firstName} ${client?.lastName}`,
                              location: `${client?.address} ${client?.city} ${client?.state} ${client?.country} (${client?.zipCode})`,
                              description: `Caregiving service scheduled at ${moment(
                                values?.startTime
                              ).format("ll")} (${moment(
                                values?.startTime
                              ).format("LT")}- ${moment(values?.endTime).format(
                                "LT"
                              )}), Note : ${values?.note}`,
                              start: {
                                dateTime: values?.startTime,
                                timeZone: "America/Los_Angeles",
                              },
                              end: {
                                dateTime: values?.endTime,
                                timeZone: "America/Los_Angeles",
                              },
                              attendees: attendees,
                              reminders: {
                                useDefault: false,
                                overrides: [
                                  { method: "email", minutes: 24 * 60 },
                                  { method: "popup", minutes: 10 },
                                ],
                              },
                            },
                          }),
                        }
                      ).then((res) => res.json()), // Handle the Google event response
                      setDoc(
                        doc(db, constant.COLLECTIONS.EVENTS, values.id),
                        body,
                        {
                          merge: true,
                        }
                      ), // Fire store event update
                    ]
                  );
                  toastAlert("success", "Event updated successfully");
                  return { success: true, googleResponse, firestoreResponse };
                }
                toastAlert("success", "Event updated successfully");
                await setDoc(
                  doc(db, constant.COLLECTIONS.EVENTS, values.id),
                  body,
                  {
                    merge: true,
                  }
                );
                return { success: true };

              } catch (err) {
                console.log("err", err);
              }
            } else {
              const eventRef = collection(db, constant.COLLECTIONS.EVENTS);
              await addDoc(eventRef, body);
            }
            return { success: true };
          } catch (error) {
            console.error("Error saving event:", error);
            throw new Error(getErrorMessage(error));
          }
        }
      } catch (error) {
        console.error("Error in mutation:", error);
        throw new Error("An error occurred during the mutation");
      }
    },
    onSuccess: (response) => {
      if (response && response?.success) handleClose();
    },
  });

  useEffect(() => {
    let data = show?.event?.extendedProps;
    if (data) {
      setValues((prevValues) => ({
        ...prevValues,
        id: data?.id,
        employeeId: data?.employeeId,
        shiftDate: new Date(data?.startTime?.toDate()),
        startTime: new Date(data?.startTime?.toDate()),
        endTime: new Date(data?.endTime?.toDate()),
        clientId: data?.clientId,
        note: data?.note,
        sync: data?.sync,
        reoccurring: `${data.reoccurring}`,
        repeatOn: data.repeatOn,
        endsOn: new Date(data?.endsOn?.toDate()),
      }));
      setEditing(true)
      if (data.client && data.client.authorizedEmployee) setAuthorized(data.client.authorizedEmployee)
    }

    if (show?.date) {
      setFieldValue("shiftDate", new Date(show?.date));
      setFieldValue("startTime", new Date(show?.date));
    }
  }, [show?.event?.extendedProps?.id || show?.date]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          deleteMutation.mutate(values?.id);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      const googleEventId = show?.event?.extendedProps?.calendarEventId;

      try {
        // If there's a calendarEventId, delete both the Google Calendar event and Firestore event
        if (googleEventId) {
          const [googleResponse, firestoreResponse] = await Promise.all([
            fetch(
              `https://us-central1-${projectId}.cloudfunctions.net/deleteEvent`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  eventId: googleEventId,
                }),
              }
            ).then((res) => res.json()), // Handle the Google event response
            deleteDoc(doc(db, constant.COLLECTIONS.EVENTS, id)), // Firestore delete
          ]);

          return { googleResponse, firestoreResponse };
        }

        // If no calendarEventId, only delete from Firestore
        return await deleteDoc(doc(db, constant.COLLECTIONS.EVENTS, id));
      } catch (error) {
        throw new Error("Failed to delete the event from one or more sources");
      }
    },
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "Event has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      handleClose();
    },
  });


  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to cancel this event!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          cancelMutation.mutate(values?.id);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const cancelMutation = useMutation({
    mutationFn: async (id) => {
      const googleEventId = show?.event?.extendedProps?.calendarEventId;

      try {
        // If there's a calendarEventId, delete both the Google Calendar event and Firestore event
        if (googleEventId) {
          const [googleResponse, firestoreResponse] = await Promise.all([
            fetch(
              `https://us-central1-${projectId}.cloudfunctions.net/deleteEvent`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  eventId: googleEventId,
                }),
              }
            ).then((res) => res.json()), // Handle the Google event response
            setDoc(
              doc(db, constant.COLLECTIONS.EVENTS, id),
              { isCancelled: true },
              { merge: true }
            )
          ]);

          return { googleResponse, firestoreResponse };
        }

        // If no calendarEventId, only delete from Firestore
        return await setDoc(
          doc(db, constant.COLLECTIONS.EVENTS, id),
          { isCancelled: true },
          { merge: true }
        )
      } catch (error) {
        throw new Error("Failed to cancel the event from one or more sources");
      }
    },
    onSuccess: () => {
      Swal.fire({
        title: "Event Cancelled!",
        text: "Event has been cancelled.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      handleClose();
    },
  });

  const handleAuthorize = (clientId) => {
    if (clientId && clientId !== "" && clientList.length) {
      const selectedClient = clientList.find(cl => cl.id == clientId)
      setAuthorized(selectedClient.authorizedEmployee)
    }
    setFieldValue('employeeId', "")
  }
  const handleSelectedEmployee = (employeeId) => {
    if (employeeId && employeeId !== "" && employeeList.length && authorized.length) {
      const isAuthorized = authorized.find(emp => emp.value === employeeId)
      if (isAuthorized) setFieldValue("employeeId", employeeId);
      else handleEmployeeSelection(employeeId)
    } else setFieldValue("employeeId", employeeId);
  }

  const handleEmployeeSelection = (employeeId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to assign this event to an unauthorized employee!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, assign it!",
    }).then((result) => {
      if (result.isConfirmed) setFieldValue("employeeId", employeeId);
    });
  };

  const parseTimeInput = (input, shiftDate) => {
    let hours, minutes;
    const isPM = /pm$/i.test(input);
    const isAM = /am$/i.test(input);

    const timeString = input.replace(/(am|pm)/i, "");  // Strip am/pm if present

    if (timeString.length === 3) {
      hours = parseInt(timeString[0], 10);
      minutes = parseInt(timeString.slice(1), 10);
    } else if (timeString.length === 4) {
      hours = parseInt(timeString.slice(0, 2), 10);
      minutes = parseInt(timeString.slice(2), 10);
    } else return null;


    if (isPM && hours < 12) hours += 12; // Adjust hours for AM/PM format
    if (isAM && hours === 12) hours = 0;
    if (!isAM && !isPM && hours >= 12) hours -= 12; // Handle 24-hour format

    const resultDate = new Date(shiftDate || Date.now());
    resultDate.setHours(hours, minutes, 0, 0);

    return resultDate;
  };

  return (
    <Modal show={!!show} onHide={handleClose} centered className="addModal">
      <Modal.Header closeButton>
        <Modal.Title>
          {!!values?.id ? "Update Schedule" : "Add Schedule"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <label className="labelTxt">
                Client <span className="text-danger">*</span>
              </label>
              <select
                name="clientId"
                className="inputBox"
                value={values?.clientId}
                onChange={(event) => {
                  handleChange(event)
                  handleAuthorize(event.target.value)
                }}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {clientList &&
                  clientList?.map((i, index) => (
                    <option
                      value={i.id}
                      key={index}
                    >{`${i?.firstName} ${i?.lastName}`}</option>
                  ))}
              </select>

              <small className="text-danger requiredTxt">
                {touched.clientId && errors.clientId}
              </small>
            </div>
          </div>
          {values.clientId && values.clientId != "" &&
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label className="labelTxt">Employee</label>
                <select
                  className="inputBox"
                  value={values?.employeeId}
                  name="employeeId"
                  // onChange={handleChange}
                  onChange={(event) => {
                    handleSelectedEmployee(event.target.value)
                  }}
                  onBlur={handleBlur}
                >
                  <option value="">Select</option>
                  {employeeList &&
                    employeeList?.map((i, index) => (
                      <option
                        key={index}
                        value={i.id}
                      >{`${i?.firstName} ${i?.lastName}`}</option>
                    ))}
                </select>

                <small className="text-danger requiredTxt">
                  {touched.employeeId && errors.employeeId}
                </small>
              </div>
            </div>}
          <div className="col-md-12 mb-3">
            <div className="form-group position-relative">
              <label className="labelTxt">
                Shift Date <span className="text-danger">*</span>
              </label>
              <img
                src={images.calendar}
                className="calanderIcon"
                alt="calendarImg"
                role="button"
                onClick={() => dateRef?.current?.setFocus()}
              />

              <DatePicker
                onChange={(date) => {
                  setFieldValue("shiftDate", date)
                  if (date && values.startTime) {
                    const combinedDate = new Date(date);
                    combinedDate.setHours(values.startTime.getHours());
                    combinedDate.setMinutes(values.startTime.getMinutes());
                    combinedDate.setSeconds(0, 0);
                    setFieldValue("startTime", combinedDate);
                  }
                  if (date && values.endTime) {
                    const combinedDate = new Date(date);
                    combinedDate.setHours(values.endTime.getHours());
                    combinedDate.setMinutes(values.endTime.getMinutes());
                    combinedDate.setSeconds(0, 0);
                    setFieldValue("endTime", combinedDate);
                  }
                }}
                className="inputBox"
                selected={values?.shiftDate}
                minDate={new Date()}
                ref={dateRef}
                onBlur={() => setFieldTouched("shiftDate", true)}
              />

              <small className="text-danger requiredTxt">
                {touched.shiftDate && errors.shiftDate}
              </small>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <label className="labelTxt">
                Scheduled Start Time <span className="text-danger">*</span>
              </label>
              <DatePicker
                selected={values?.startTime}
                onChange={(date) => {
                  if (date && values?.shiftDate) {
                    const combinedDate = new Date(values.shiftDate);
                    combinedDate.setHours(date.getHours());
                    combinedDate.setMinutes(date.getMinutes());
                    combinedDate.setSeconds(0, 0);
                    setFieldValue("startTime", combinedDate);
                  }
                }}
                onChangeRaw={(e) => {
                  const input = e.target.value.trim();
                  if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                    const parsedDate = parseTimeInput(input, values?.shiftDate);
                    if (parsedDate) {
                      setFieldValue("startTime", parsedDate);
                    }
                  }
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="inputBox"
                onBlur={() => setFieldTouched("startTime", true)}
              />
              <small className="text-danger requiredTxt">
                {touched.startTime && errors.startTime}
              </small>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <label className="labelTxt">
                Scheduled End Time <span className="text-danger">*</span>
              </label>

              <DatePicker
                selected={values?.endTime}
                onChange={(date) => {
                  if (date && values?.shiftDate) {
                    const combinedDate = new Date(values.shiftDate);
                    combinedDate.setHours(date.getHours());
                    combinedDate.setMinutes(date.getMinutes());
                    combinedDate.setSeconds(0, 0);
                    setFieldValue("endTime", combinedDate);
                  }
                }}
                onChangeRaw={(e) => {
                  const input = e.target.value.trim();
                  if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                    const parsedDate = parseTimeInput(input, values?.shiftDate);
                    if (parsedDate) {
                      setFieldValue("endTime", parsedDate);
                    }
                  }
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="inputBox"
                onBlur={() => setFieldTouched("endTime", true)}
              />

              <small className="text-danger requiredTxt">
                {touched.endTime && errors.endTime}
              </small>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <label className="labelTxt">Reocurring Events</label>
              <select
                className="inputBox"
                name="reoccurring"
                value={values?.reoccurring}
                disabled={editing}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          {values?.reoccurring == "true" && (
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label className="labelTxt">
                  Repeat on <span className="text-danger">*</span>
                </label>
                <Row>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="Sun"
                        name="repeatOn"
                        value={0}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("0")}
                      />
                      <label className="daysLabel" htmlFor="Sun">
                        Sunday
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="mon"
                        name="repeatOn"
                        value={1}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("1")}
                      />
                      <label className="daysLabel" htmlFor="mon">
                        Monday
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="Tue"
                        name="repeatOn"
                        value={2}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("2")}
                      />
                      <label className="daysLabel" htmlFor="Tue">
                        Tuesday
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="Wed"
                        name="repeatOn"
                        value={3}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("3")}
                      />
                      <label className="daysLabel" htmlFor="Wed">
                        Wednesday
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="Thu"
                        name="repeatOn"
                        value={4}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("4")}
                      />
                      <label className="daysLabel" htmlFor="Thu">
                        Thursday
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="Fri"
                        name="repeatOn"
                        value={5}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("5")}
                      />
                      <label className="daysLabel" htmlFor="Fri">
                        Friday
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="days">
                      <input
                        type="checkbox"
                        id="Sat"
                        name="repeatOn"
                        value={6}
                        disabled={editing}
                        onChange={handleChange}
                        checked={values?.repeatOn?.includes("6")}
                      />
                      <label className="daysLabel" htmlFor="Sat">
                        Saturday
                      </label>
                    </div>
                  </Col>
                  <small className="text-danger requiredTxt">
                    {touched.repeatOn && errors.repeatOn}
                  </small>
                </Row>
              </div>
              <div className="form-group">
                <label className="labelTxt">
                  Ends On <span className="text-danger">*</span>
                </label>
                <div className="form-group position-relative">
                  <img
                    src={images.calendar}
                    className="calanderIcon"
                    alt="calendarImg"
                    role="button"
                    onClick={() => endDateRef?.current?.setFocus()}
                  />

                  <DatePicker
                    onChange={(date) => setFieldValue("endsOn", date)}
                    className="inputBox"
                    disabled={editing}
                    selected={values?.endsOn}
                    minDate={new Date()}
                    ref={endDateRef}
                    onBlur={() => setFieldTouched("endsOn", true)}
                  />
                </div>
                <small className="text-danger requiredTxt">
                  {touched.endsOn && errors.endsOn}
                </small>
              </div>
            </div>
          )}
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <label className="labelTxt">Note</label>
              <textarea
                rows={3}
                name="note"
                className="inputBox"
                value={values?.note}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <small className="text-danger requiredTxt">
                {touched.note && errors.note}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-around ">
          {!!values?.id && (
            <button
              onClick={handleDelete}
              type="button"
              className="cancleBtn delete"
            // disabled={show?.event?.extendedProps && show?.event?.extendedProps.isCancelled}
            >
              Delete
            </button>
          )}
          {!!values?.id && (
            <button
              onClick={handleCancel}
              type="button"
              className="greenBtn"
              disabled={show?.event?.extendedProps && show?.event?.extendedProps.isCancelled}
            >
              Cancel
            </button>
          )}
          <button onClick={handleClose} type="button" className="cancleBtn">
            Close
          </button>
          <button className="greenBtn" type="button" onClick={handleSubmit} disabled={show?.event?.extendedProps && show?.event?.extendedProps.isCancelled}>
            Save Changes
          </button>

        </div>

      </Modal.Body>
      {(mutation?.isPending || deleteMutation?.isPending) && <Loader />}
    </Modal>
  );
};

export default AddEvent;
