import { useQuery } from "@tanstack/react-query";
import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment/moment";
import React, { useState } from "react";
import * as images from "../../assets/image";
import { db, eventRef, usersRef } from "../../firebase/FirebaseConfig";
import useDetails from "../../hooks/useDetails";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";

const Shifts = () => {
  const details = useDetails();
  const [search, setSearch] = useState();

  const { data, refetch } = useQuery({
    queryKey: ["shift-list"],
    queryFn: async () => {
      try {
        const userQuery = query(usersRef, where("uid", "==", details?.uid));
        const userSnapshot = await getDocs(userQuery);

        const currentUserDoc = userSnapshot.docs.find((doc) => !!doc);
        if (!currentUserDoc) {
          toastAlert("error", "User not found");
          return [];
        }

        const currentUserId = currentUserDoc.id;
        const currentDate = new Date();

        const shiftQuery = query(
          eventRef,
          where("employeeId", "==", currentUserId),
          where("startTime", ">=", currentDate),
          orderBy("startTime", "asc")
        );

        const shiftSnapshot = await getDocs(shiftQuery);
        const eventsData = shiftSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const promises = eventsData.map(async (event) => {
          const clientDoc = await getDoc(
            doc(db, constant.COLLECTIONS.USERS, event.clientId)
          );

          return {
            ...event,
            client: clientDoc.data(),
          };
        });

        let eventsWithDetails = await Promise.all(promises);

        if (search) {
          eventsWithDetails = eventsWithDetails.filter((event) =>
            [
              event.client?.email,
              event.client?.firstName,
              event.client?.lastName,
            ].some((field) =>
              field?.toLowerCase().includes(search.toLowerCase())
            )
          );
        }

        return eventsWithDetails;
      } catch (err) {
        console.error("Error fetching shift list:", err);
        throw err;
      }
    },
  });
  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Upcoming Schedules</h4>
        <div className="endContent">
          <div className="clientSearch">
            <img
              src={images.Search}
              alt="searchImg"
              className="clientSearchIcon"
            />
            <input
              type="text"
              placeholder="Type and press enter to search."
              className="clientSearchInput"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) =>
                e.key == "Enter" && search.trim() !== "" && refetch()
              }
              onKeyUp={(e) =>
                e.target.value == "" && e.key == "Backspace" && refetch()
              }
            />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    <th className="heading14 tableHeading">S. No</th>
                    <th className="heading14 tableHeading">Date</th>
                    <th className="heading14 tableHeading">Time</th>
                    <th className="heading14 tableHeading">Client Name</th>
                    <th className="heading14 tableHeading">Note</th>
                    <th className="heading14 tableHeading">Address</th>
                    <th className="heading14 tableHeading">Phone Number</th>
                    <th className="heading14 tableHeading">Email</th>
                    <th className="heading14 tableHeading">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.length > 0 ? (
                    data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="heading14 tableHeading">
                            {index + 1}
                          </td>
                          <td className="heading14 tableHeading">
                            {moment(item?.startTime?.toDate()).format("ll")}
                          </td>
                          <td className="heading14 tableHeading">
                            {moment(item?.startTime?.toDate()).format("LT")} -{" "}
                            {moment(item?.endTime?.toDate()).format("LT")}
                          </td>
                          <td className="text-capitalize heading14 tableHeading">{`${item?.client?.firstName} ${item?.client?.lastName}`}</td>
                          <td className="heading14 tableHeading">
                            {item?.note}
                          </td>
                          <td className="heading14 tableHeading">{`${item?.client?.address} ${item?.client?.city} ${item?.client?.state} ${item?.client?.country} (${item?.client?.zipCode})`}</td>
                          <td className="heading14 tableHeading">
                            {item?.client?.phoneNumber}
                          </td>
                          <td className="heading14 tableHeading">
                            {item?.client?.email}
                          </td>

                          <td className="heading14 tableHeading">
                            {moment(item?.createdAt?.toDate()).format("lll")}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Shifts;
