import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import moment from "moment";
import React, { useRef, useState } from "react";
import { FaCalendar } from "react-icons/fa6";
import { MdAssignmentInd } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import Swal from "sweetalert2";
import Loader from "../../common/loader/Loader";
import {
  db,
  eventRef,
  projectId
} from "../../firebase/FirebaseConfig";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
import { fetchEmployees, fetchClientDetails, fetchEmployeeDetails, conflictedEvent, employeeTotalDuration, getMinuteDifference } from "../../utils/function";
import AddEvent from "./AddEvent";

const Calender = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const calendarRef = useRef(null);
  const [currentView, setCurrentView] = useState('');

  const getEventClasses = (eventObj, employeeDuration) => {
    let classname = ""
    let isOverBooked = false
    if (eventObj?.isCancelled) classname = "cancelled_event"  // Cancelled events
    else if (!eventObj?.employeeId || eventObj?.employeeId == "") classname = "unassigned_event"  // Not Assigned
    // else if (eventObj?.employeeId && !eventObj?.sync && isOverBooked) classname = "overbooked_event" // Assigned but not synced
    else if (eventObj?.employeeId && !eventObj?.sync) classname = "notsync_event" // Assigned but not synced
    else if (eventObj?.employeeId && eventObj?.sync) classname = "synced_assigned_event" // Assigned and synced
    else if (isOverBooked) classname = "overbooked_event"

    return classname
  }

  const getEventStyle = (eventObj, employeeDuration) => {
    let classname = ""
    let backgroundColor = "red"
    let isOverBooked = false

    const startDate = new Date(eventObj?.startTime?.toDate());  // Handle timezone shift
    const fullDate = startDate.toISOString().split('T')[0];

    if (employeeDuration
      && employeeDuration[fullDate]
      && employeeDuration[fullDate][eventObj.employeeId]
      && employeeDuration[fullDate][eventObj.employeeId] >= constant.MAX_DURATION_PER_EMPLOYEE) {
      isOverBooked = true
    }

    if (eventObj?.isCancelled) { // Cancelled events
      classname = "cancelled_event"
      backgroundColor = "blue"
    } else if (!eventObj?.employeeId || eventObj?.employeeId == "") { // Not Assigned
      classname = "unassigned_event"
      backgroundColor = "red"
    } else if (eventObj?.employeeId && !eventObj?.sync && isOverBooked) { // Employee overboooked over 8 hours and the vent is not synced
      classname = "overbooked_event"
      backgroundColor = "red"
    } else if (eventObj?.employeeId && !eventObj?.sync) { // Assigned but not synced
      classname = "notsync_event"
      backgroundColor = "red"
    } else if (eventObj?.employeeId && eventObj?.sync) {  // Assigned and synced
      classname = "synced_assigned_event"
      backgroundColor = "green"
    } else if (isOverBooked) {
      classname = "overbooked_event"
      backgroundColor = "red"
    }


    return { backgroundColor: backgroundColor, className: classname }
  }

  const { data, refetch } = useQuery({
    queryKey: ["event-list"],
    queryFn: async () => {
      return new Promise((resolve, reject) => {
        try {
          onSnapshot(query(eventRef, orderBy("createdAt", "asc")), async (snapshot) => {
            const eventsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            let employeeDuration = {}
            const promises = eventsData.map(async (event) => {
              const clientDoc = await getDoc(
                doc(db, constant.COLLECTIONS.USERS, event.clientId)
              );

              let employeeDoc = null;
              if (event.employeeId) {
                employeeDoc = await getDoc(
                  doc(db, constant.COLLECTIONS.USERS, event.employeeId)
                );
              }
              const startDate = new Date(event?.startTime?.toDate())  // 8 hours color shift code 
              const fullDate = startDate.toISOString().split('T')[0]
              if (event.employeeId && event.employeeId !== "") {
                if (employeeDuration[fullDate]) {
                  const innerObject = { ...employeeDuration[fullDate] }
                  if (innerObject[event.employeeId]) {
                    innerObject[event.employeeId] += event.duration
                  } else {
                    innerObject[event.employeeId] = event.duration
                  }
                  employeeDuration[fullDate] = innerObject
                } else employeeDuration[fullDate] = { [event.employeeId]: event.duration }
              }
              const styleProperies = getEventStyle(event, employeeDuration)

              return {
                start: event?.startTime?.toDate(),
                end: event?.endTime?.toDate(),
                title: `Client: ${clientDoc.data()?.firstName} ${
                  clientDoc.data()?.lastName
                }, Employee: ${
                  event.employeeId
                    ? `${employeeDoc?.data()?.firstName} ${
                        employeeDoc?.data()?.lastName ?? ""
                      }`
                    : "N/A"
                  }, Sync : ${event?.sync ? "True" : "False"} `,
                description: event?.notes,
                extendedProps: {
                  ...event,
                  client: clientDoc.data(),
                  employee: employeeDoc?.data() || null,
                },
                ...styleProperies,
                textColor: event.isCancelled ? "white" : "black",
              };
            });

            const eventsWithDetails = await Promise.all(promises);
            resolve(eventsWithDetails);
          });
        } catch (err) {
          console.log("error", err);
          reject(err);
        }
      });
    },
  });

  const fetchSyncableEvents = async () => {
    try {
      const eventsQuery = query(
        eventRef,
        where("sync", "==", false),
        where("employeeId", "!=", ""),
        where("isCancelled", "==", false),
        where("startTime", ">", new Date())
      );

      const querySnapshot = await getDocs(eventsQuery);
      const eventsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch client and employee details
      const eventsWithDetails = await Promise.all(
        eventsData.map(async (event) => {
          const clientDetails = await fetchClientDetails(event.clientId);
          const employeeDetails = await fetchEmployeeDetails(event.employeeId);

          return {
            ...event,
            client: clientDetails,
            employee: employeeDetails,
          };
        })
      );

      return eventsWithDetails;
    } catch (error) {
      console.error("Error fetching events: ", error);
      return [];
    }
  };

  const handleSync = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const syncMutation = useMutation({
    mutationFn: async () => {

      let data = await fetchSyncableEvents();

      if (!data.length) return { calendarResp: [], eventData: [], noEvents: true };

      let events = data?.map((item) => {
        const attendees = [];

        if (item?.employee?.email) {
          attendees.push({ email: item.employee.email });
        }

        if (item?.client?.email) {
          attendees.push({ email: item.client.email });
        }

        return {
          summary: `Appointment for ${item?.employee?.firstName} ${item?.employee?.lastName} with ${item?.client?.firstName} ${item?.client?.lastName}`,
          location: `${item?.client?.address} ${item?.client?.city} ${item?.client?.state} ${item?.client?.country} (${item?.client?.zipCode})`,
          description: `Caregiving service scheduled at ${moment(
            item?.startTime?.toDate()
          ).format("ll")} (${moment(item?.startTime?.toDate()).format(
            "LT"
          )}- ${moment(item?.endTime?.toDate()).format("LT")}), Note : ${item?.note
            }`,
          start: {
            dateTime: item?.startTime?.toDate(),
            timeZone: "America/Los_Angeles",
          },
          end: {
            dateTime: item?.endTime?.toDate(),
            timeZone: "America/Los_Angeles",
          },
          attendees: attendees,
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        };
      });

      const response = await fetch(
        `https://us-central1-${projectId}.cloudfunctions.net/createEvent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ events: events }),
        }
      );

      return { calendarResp: await response.json(), eventData: data };
    },

    onSuccess: async (resp) => {
      console.log("Sync Events", resp)

      if (!resp.eventData.length && resp?.noEvents) toastAlert("warning", "No events available to sync");
      if (!resp.eventData.length) return;

      if (resp?.eventData?.length !== resp?.calendarResp?.data?.length) {
        console.error("Mismatch between eventData and calendarResp");
        return;
      }

      const batch = writeBatch(db);
      resp?.eventData?.forEach((item, index) => {
        const calendarEventId = resp?.calendarResp?.data?.[index]?.id;
        if (!calendarEventId) {
          console.error(`No calendarEventId for item at index ${index}`);
          return;
        }

        const docRef = doc(db, constant.COLLECTIONS.EVENTS, item?.id);
        batch.set(
          docRef,
          { sync: true, calendarEventId }, // Save the calendarEventId
          { merge: true }
        );
      });

      try {
        await batch.commit();
        toastAlert("success", resp?.calendarResp?.message);
        refetch(); // Refetch the data after the batch commit
      } catch (error) {
        console.error("Error updating documents: ", error);
      }
    },
  });

  // Auto Assigning events  code starts here  
  const checkAvailability = async (employeeArray, eventTiming = {}, from) => {
    try {
      const { eventStart, eventTill, eventId, eventDuration } = eventTiming
      const weekDay = eventStart.day()
      let matchedEmployee;
      for (let employee of employeeArray) {
        if (!employee?.workingDays?.includes(String(weekDay))) continue;

        const employeeStartTime = moment(`${eventStart.format('YYYY-MM-DD')} ${employee.workingHours.startTime}`, 'YYYY-MM-DD hh:mm:ss'); // employee starting hours
        const employeeEndTime = moment(`${eventStart.format('YYYY-MM-DD')} ${employee.workingHours.endTime}`, 'YYYY-MM-DD hh:mm:ss'); // employee ending hours

        if (eventStart.isBefore(employeeStartTime)) continue;  // check if the event is stating before the emplayee availablity
        if (eventTill.isAfter(employeeEndTime)) continue;  // check if the event is ending after the employee availability

        const conflictcheck = {
          employeeId: employee.id,
          startTime: new Date(eventStart),
          endTime: new Date(eventTill),
          eventId,
          eventDuration
        }

        const haveWorkWindow = await employeeTotalDuration(conflictcheck)
        if (!haveWorkWindow) continue;

        const available = await conflictedEvent(conflictcheck)
        if (!available) continue;

        matchedEmployee = employee

        break;
      }
      return matchedEmployee
    } catch (error) {
      console.log(error, 'eeeeeeeee')
    }
  }

  const assignEmployess = async (events = [], employees = []) => {
    try {
      let assignedCount = 0
      let unassignedCount = 0
      if (events.length && employees.length) {
        const batch = writeBatch(db);
        const eventPromises = events.map(async (event) => {
          const authorizedEmployees = event?.client?.authorizedEmployee?.length ? event.client.authorizedEmployee.map((user) => user.value) : [];
          const eventStart = moment(event.startTime.toDate()); // event Start time
          const eventTill = moment(event.endTime.toDate()); // event end time

          let preferred = []; // only preferred employees
          const notPreferred = []; // not preferred employees will be set in this array

          // Employees loop for filtering out preferred and not preferred employees
          employees.forEach((emp) => authorizedEmployees.includes(emp.id) ? preferred.push(emp) : notPreferred.push(emp));

          let matchedEmployee;
          // if (preferred.length) {
          //   const promises = preferred.map(async (employee) => {
          //     console.log('asdasd', employee)
          //     const conflictcheck = {
          //       employeeId: employee.id,
          //       startTime: new Date(eventStart),
          //       endTime: new Date(eventTill),
          //       eventId: event?.id,
          //       eventDuration: event?.duration || 0
          //     }

          //     const totalWorkingTime = await employeeTotalDuration(conflictcheck)
          //     return { ...employee, totalWorkingTime }
          //   })

          //   preferred = await Promise.all(promises)
          //   console.log(preferred, "Preffered")
          // }
          // Availability check and employee find from the preferred ones
          if (preferred.length) matchedEmployee = await checkAvailability(preferred, { eventStart, eventTill, eventId: event?.id, eventDuration: event?.duration || 0 }, 'preferred');
          // if (!matchedEmployee && notPreferred.length) matchedEmployee = await checkAvailability(notPreferred, { eventStart, eventTill, eventId: event?.id, eventDuration: event?.duration || 0 }, 'not-preferred');

          if (matchedEmployee) {
            assignedCount += 1
            console.log(`Event ${event.id}-${eventStart.format('YYYY-MM-DD')}-${event.client.firstName} : has been assigned to ${matchedEmployee?.firstName}--${matchedEmployee?.id}`);
            const docRef = doc(db, constant.COLLECTIONS.EVENTS, event?.id);
            batch.set(docRef, { employeeId: matchedEmployee?.id }, { merge: true });
          } else {
            unassignedCount += 1
            console.log(`Event ${event.id}-${eventStart.format('YYYY-MM-DD')} : not assigned due to unavailability of employees`);
          }
        });

        // Run all event promises concurrently
        await Promise.all(eventPromises);

        await batch.commit();
        return { success: true, message: "Employees Assigned", assignedCount, unassignedCount }
      }
    } catch (error) {
      console.log(error)
      return { success: false, message: "Something went wrong", error: error }
    }
  }

  const getUnAssignedEvents = async () => {
    try {
      const eventsQuery = query(
        eventRef,
        where("employeeId", "==", ""),
        where("isCancelled", "==", false),
        where("sync", "==", false),
        where("startTime", ">", new Date())
      );

      const querySnapshot = await getDocs(eventsQuery);
      const eventsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch client and employee details
      const eventsWithDetails = await Promise.all(
        eventsData.map(async (event) => {
          const clientDetails = await fetchClientDetails(event.clientId);
          const employeeDetails = await fetchEmployeeDetails(event.employeeId);

          return {
            ...event,
            client: clientDetails,
            employee: employeeDetails,
          };
        })
      );

      return eventsWithDetails;
    } catch (error) {
      console.error("Error fetching events: ", error);
      return [];
    }
  };

  const autoAssignEvents = async () => {
    try {
      const events = await getUnAssignedEvents();
      let allDays = []
      if (!events.length) return { success: false, noEvents: true, message: "No events found" }

      events.forEach(item => {
        if (item?.reoccurring == true) {
          allDays.push(...item.repeatOn)
        } else {
          const date = new Date(item?.startTime?.toDate())
          const dayOfWeek = date.getDay()
          allDays.push(String(dayOfWeek))
        }
      })
      const daysSet = new Set([...allDays])
      const availabilty = [...daysSet]
      if (!availabilty.length) return { success: false, message: "No Availbilty fetched" }

      const employees = await fetchEmployees(availabilty);
      if (!employees.length) return { success: false, message: "No employees available" }

      const { success, message, error, assignedCount, unassignedCount } = await assignEmployess(events, employees)
      if (error) return { success: success, error: error }

      return { success: true, message: `Total ${assignedCount} events are assigned` }
    } catch (error) {
      return { success: false, error: error, message: "Something went wrong" }
    }
  }

  const assignMutation = useMutation({
    mutationFn: async () => {
      const assigningEvents = await autoAssignEvents()
      return assigningEvents
    },
    onSuccess: async (resp) => {
      console.log("Sync Events", resp)
      if (!resp.success) {
        if (resp.noEvents) toastAlert("warning", resp.message);
        console.log("Auto assign Error :>", resp)
        return;
      } else {
        refetch();
        toastAlert("success", resp.message);
      }

      return;
    },
  });

  const handleAutoAssign = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are assigning the unassigned events",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          assignMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };
  // Auto Assigning events code ends here 

  const handleDateClick = (info) => {
    const clickedDate = new Date(info.date);
    const today = new Date();

    if (clickedDate >= today.setHours(0, 0, 0, 0)) {
      if (currentView == "timeGridDay" || currentView == "timeGridWeek") {
        const today = new Date();
        if (clickedDate >= today) setShow(info)
      } else {
        const selectedDate = info.dateStr;
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.changeView('timeGridDay', selectedDate);
        }
      }
    } else console.log("Past date selected")
  };

  const handleDragging = ({ event, revert }) => {
    if (event?.extendedProps && event?.extendedProps.isCancelled) {
      toastAlert("warning", "Cannot update cancelled events");
      revert()
      return;
    }
    const updatedStart = new Date(event?.start)
    const updatedEnd = new Date(event?.end)
    if (event && event.extendedProps && event?.extendedProps?.id && event.extendedProps.id !== "") {
      const oldEventStart = new Date(event.extendedProps.startTime.toDate())
      const oldEventEnd = new Date(event.extendedProps.endTime.toDate())
      if (oldEventStart.getTime() !== updatedStart.getTime() || oldEventEnd.getTime() !== updatedEnd.getTime()) {
        const minuteDiff = getMinuteDifference(event?.start, event?.end) // getting time different 
        dragMutation.mutate({
          startTime: updatedStart,
          endTime: updatedEnd,
          eventId: event.extendedProps.id,
          duration: minuteDiff
        })
      }
    }
  }

  const dragMutation = useMutation({
    mutationFn: async (body) => {
      const { eventId, ...rest } = body
      return await setDoc(doc(db, constant.COLLECTIONS.EVENTS, eventId), rest, { merge: true })
    },
    onSuccess: async () => {
      toastAlert("success", "Event updated successfully");
      refetch();
    }
  })

  return (
    <div className="main-content">
      <div className="commonSearchBar my-3">
        <h4>Employee Management</h4>
        <div className="endContent">
          <button className="greenBtn" onClick={handleAutoAssign}>
            <MdAssignmentInd size={30} />
          </button>
          <button className="greenBtn" onClick={handleSync}>
            <FaCalendar size={27} />
          </button>
          <button className="greenBtn" onClick={handleShow}>
            <IoMdAdd  size={35} />
          </button>
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        datesSet={(arg) => setCurrentView(arg.view.type)}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
          listPlugin,
        ]}
        events={data}
        editable={true}
        eventResizableFromStart={true}
        eventResize={handleDragging}
        eventDrop={({ revert }) => revert()}
        eventClick={(el) => {
          const clickedEventDate = new Date(el.event.start);
          const today = new Date();

          if (clickedEventDate >= today.setHours(0, 0, 0, 0)) {
            setShow(el);
          }
        }}
        dayMaxEventRows={true}
        nowIndicator={true}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        dateClick={handleDateClick}
        eventDidMount={(info) => {
          info.el.setAttribute("title", info.event.title);
        }}
      />

      {show && <AddEvent show={show} setShow={setShow} refetch={refetch} />}
      {(dragMutation?.isPending || assignMutation?.isPending || syncMutation?.isPending) && (< Loader />)}
    </div>
  );
};

export default Calender;
