import { useQuery } from "@tanstack/react-query";
import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavigateBack from "../../common/NavigateBack";
import { db, payoutRef } from "../../firebase/FirebaseConfig";
import { constant } from "../../utils/constants";
import { getDaysString } from "../../utils/function";

const EmployeDetails = () => {
  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["client-details", id],
    queryFn: async () => {
      try {
        const userDoc = await getDoc(doc(db, constant.COLLECTIONS.USERS, id));

        if (userDoc.exists()) {
          const data = userDoc.data();
          return data;
        } else {
          toastAlert("error", "User does not exist");
        }
      } catch (error) {
        console.log("error", error);
        toastAlert("error", "An error occurred while fetching user data");
      }
    },
  });

  const { data: payOutData } = useQuery({
    queryKey: ["payout-list"],
    queryFn: async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const payOutQuery = query(payoutRef, where("employeeId", "==", id));

          const payOutSnapshot = await getDocs(payOutQuery);

          const payOutData = payOutSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const promises = payOutData.map(async (event) => {
            const clientDoc = await getDoc(
              doc(db, constant.COLLECTIONS.USERS, event.clientId)
            );

            return {
              ...event,
              client: clientDoc.data(),
            };
          });

          let eventsWithDetails = await Promise.all(promises);

          resolve(eventsWithDetails);
        } catch (err) {
          console.log("error", err);
          reject(err);
        }
      });
    },
  });

  return (
    <>
      <section className="main-content">
        <NavigateBack>Employee Details</NavigateBack>
        <Card border="light" bg="light" className="p-4">
          <div className="clientDetails">
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Employee Name</h6>
              <span className="heading16 ps-2 text-capitalize">{`${data?.firstName} ${data?.lastName}`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Email</h6>
              <span className="heading16 ps-2">{data?.email}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Phone Number</h6>
              <span className="heading16 ps-2">{data?.phoneNumber}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Hourly Rate</h6>
              <span className="heading16 ps-2">${data?.hourlyRate}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Address</h6>
              <span className="heading16 ps-2">{`${data?.address} ${data?.city} ${data?.state} ${data?.country} (${data?.zipCode})`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Created At</h6>
              <span className="heading16 ps-2">
                {moment(data?.createdAt?.toDate()).format("lll")}
              </span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Available On</h6>
              <span className="heading16 ps-2">
                {data?.workingDays && data?.workingDays.length ? getDaysString(data?.workingDays.sort()) : "Not set"}
              </span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Preffered Hours</h6>
              <span className="heading16 ps-2">
                {data?.workingHours?.startTime ? data?.workingHours?.startTime : "Not set"}{" "}
                to {" "}
                {data?.workingHours?.endTime ? data?.workingHours?.endTime : "Not set"}
              </span>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-md-12">
                <h6 className="heading20 mb-4">Payout Details</h6>

                <div className="table-responsive">
                  <table className="commonTable">
                    <thead className="tableHead">
                      <tr>
                        <th className="heading14 tableHeading">S. No</th>
                        <th className="heading14 tableHeading">Client Name</th>
                        <th className="heading14 tableHeading">Email</th>
                        <th className="heading14 tableHeading">Phone Number</th>
                        <th className="heading14 tableHeading">Shift Date</th>
                        <th className="heading14 tableHeading">Check-In</th>
                        <th className="heading14 tableHeading">Check-Out</th>
                        <th className="heading14 tableHeading">Amount</th>
                        <th className="heading14 tableHeading">Note</th>
                        <th className="heading14 tableHeading">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payOutData && payOutData?.length > 0 ? (
                        payOutData?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="heading14 tableHeading">
                                {index + 1}
                              </td>
                              <td className="text-capitalize heading14 tableHeading">{`${item?.client?.firstName} ${item?.client?.lastName}`}</td>
                              <td className="heading14 tableHeading">
                                {item?.client?.email}
                              </td>
                              <td className="heading14 tableHeading">
                                {item?.client?.phoneNumber}
                              </td>
                              <td className="heading14 tableHeading">
                                {moment(item?.shiftDate?.toDate()).format("ll")}
                              </td>

                              <td className="heading14 tableHeading">
                                {moment(item?.checkIn, "HH:mm").format(
                                  "hh:mm A"
                                )}
                              </td>
                              <td className="heading14 tableHeading">
                                {moment(item?.checkOut, "HH:mm").format(
                                  "hh:mm A"
                                )}
                              </td>
                              <td className="heading14 tableHeading">
                               ${item?.amount}
                              </td>
                              <td className="heading14 tableHeading">
                                {item?.note}
                              </td>
                              <td className="heading14 tableHeading">
                                {moment(item?.createdAt?.toDate()).format(
                                  "lll"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={10}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default EmployeDetails;
