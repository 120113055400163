import { useMutation } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaKey, FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import * as images from "../assets/image";
import { auth } from "../firebase/FirebaseConfig";
import { getErrorMessage } from "../firebase/errors";
import useDetails from "../hooks/useDetails";
import Loader from "./loader/Loader";
import useRole from "../hooks/useRole";
import { constant } from "../utils/constants";

const Navbar = () => {
  const details = useDetails();
  const navigate = useNavigate();
  const role = useRole();

  const logoutMutation = useMutation({
    mutationFn: async () => {
      try {
        await signOut(auth);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });

  return (
    <>
      <header className="header">
        <h3 className="headerHeading">{/* <IoReorderThree size={30} /> */}</h3>
        <div className="headerRight">
          <div className="profileArea">
            <Dropdown className="profileDropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <span className="img-ic">
                  <img
                    src={
                      details?.profileImg ? details?.profileImg : images.profile
                    }
                    alt="Image"
                    className="me-3 profileImg"
                  />
                </span>
                <label className="text-capitalize">{`${details?.firstName} ${details?.lastName}`}</label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      role == constant.ROLE.ADMIN
                        ? "/admin/profile"
                        : "/profile"
                    )
                  }
                >
                  <FaUser className="me-2" />
                  Profile
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      role == constant.ROLE.ADMIN
                        ? "/admin/change-password"
                        : "/change-password"
                    )
                  }
                >
                  <FaKey className="me-2" />
                  Change Password
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    logoutMutation.mutate();
                  }}
                >
                  <FiLogOut className="me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
      {logoutMutation?.isPending && <Loader />}
    </>
  );
};

export default Navbar;
