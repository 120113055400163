import { useMutation } from "@tanstack/react-query";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getDocs, query, Timestamp, where } from "firebase/firestore";
import { useFormik } from "formik";
import React from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { getErrorMessage } from "../../firebase/errors";
import { auth, usersRef } from "../../firebase/FirebaseConfig";
import { isLogin, login } from "../../redux/features/authSlice";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";

const Login = () => {
  const dispatch = useDispatch();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
    },
    validationSchema: yup.object().shape({
      email: yup.string().required().email().label("Email"),
      password: yup.string().required().label("Password"),
    }),
    onSubmit: async (values) => {
      mutaiton.mutate({
        email: values?.email?.trim(),
        password: values?.password,
      });
    },
  });

  const mutaiton = useMutation({
    mutationFn: async ({ email, password }) => {
      try {
        const q = query(usersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        const currentUserDoc = querySnapshot.docs.find((doc) => !!doc);
        if (!currentUserDoc) {
          toastAlert("error", "User not found");
          return false;
        }

        let currentUser = currentUserDoc.data();

        if (currentUser.role === constant.ROLE.CLIENT) {
          toastAlert("error", "You are not allowed to login");
          dispatch(isLogin(false));
          return false;
        }

        if (!currentUser.uid) {
          toastAlert("error", "Invalid user UID");
          dispatch(isLogin(false));
          return false;
        }

        await signInWithEmailAndPassword(auth, email, password);

        if (currentUser?.createdAt instanceof Timestamp) {
          currentUser.createdAt = currentUser.createdAt.toDate().toISOString();
        }

        return currentUser;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: (resp) => {
      dispatch(login(resp));
    },
  });

  const togglePasswordVisibility = () => {
    setFieldValue("showPassword", !values?.showPassword);

    setTimeout(() => {
      setFieldValue("showPassword", false);
    }, 1000);
  };

  return (
    <div className="mainOuter">
      <section className="loginOuter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="loginPage">
                <div className="loginContent">
                  <img
                    src={images.calender}
                    alt="image"
                    className="mb-4 logoImg"
                  />
                  <h2 className="heading24">Sign in to your account</h2>
                  <form onSubmit={handleSubmit} className="loginForm">
                    <div className="form-group mb-2">
                      <label className="heading16 mb-1">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.email && errors?.email}
                      </small>
                    </div>
                    <div className="mb-3">
                      <div className="form-group position-relative">
                        <label className="heading16 mb-2">Password</label>
                        <input
                          className="inputBox"
                          type={values?.showPassword ? "text" : "password"}
                          autoComplete="off"
                          placeholder="Password"
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibility}
                        >
                          {values?.showPassword ? (
                            <FaRegEyeSlash />
                          ) : (
                            <FaRegEye />
                          )}
                        </div>
                      </div>
                      <small className="text-danger requiredTxt">
                        {touched?.password && errors?.password}
                      </small>
                    </div>

                    <button className="signInBtn" type="submit">
                      Sign in
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {mutaiton?.isPending && <Loader />}
    </div>
  );
};

export default Login;
