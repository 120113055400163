export const getErrorMessage = (error) => {
  switch (error.code) {
    case "auth/invalid-email":
      return "Invalid email address.";
    case "auth/user-not-found":
      return "User not found.";
    case "auth/wrong-password":
      return "Incorrect password.";
    case "auth/invalid-credential":
      return "Invalid credential";
    case "auth/email-already-in-use":
      return "User with this email already exists";
    default:
      return "An error occurred. Please try again.";
  }
};
