import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import React from "react";
import { Card, Form } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import { getErrorMessage } from "../../firebase/errors";
import { db, usersRef } from "../../firebase/FirebaseConfig";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import { generatePassword } from "../../utils/function";

const AddClient = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");

  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setValues,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      role: constant.ROLE.CLIENT,
      authorizedEmployee: [],
    },
    validationSchema: yup.object().shape({
      firstName: yup
        .string()
        .required()
        .label("First name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "First name should contain letters only"
        ),
      lastName: yup
        .string()
        .label("Last name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Last name should contain letters only"
        ),
      email: yup.string().required().email().label("Email").trim(),
      phoneNumber: yup
        .string()
        .min(5)
        .label("Phone Number")
        .test("phone-validate", "Invalid phone number", function (value) {
          if (value?.length > 6) {
            return isValidPhoneNumber(String(value));
          } else {
            return true;
          }
        }),
      address: yup.string().required().label("Address").trim().min(2),
      city: yup.string().required().label("City").trim().min(2),
      state: yup.string().required().label("State").trim().min(2),
      country: yup.string().required().label("Country").trim().min(2),
      zipCode: yup
        .string()
        .required()
        .label("Zip Code")
        .trim()
        .min(2)
        .matches(/^[a-zA-Z0-9]*$/, "Invalid zip code"),
    }),
    onSubmit: (values) => {
      let body = {
        ...values,
        createdAt: Timestamp.now(),
      };
      if (!id || id == "") {
        body.password = generatePassword(body)
        if (!body.password || body.password == "") toastAlert("error", "Error while creating password")
      }
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        const q = query(usersRef, where("email", "==", body.email));

        const querySnapshot = await getDocs(q);

        if (!id & !querySnapshot.empty) {
          const error = new Error();
          error.code = "auth/email-already-in-use";
          throw error;
        } else {
          await (!!id
            ? setDoc(doc(db, constant.COLLECTIONS.USERS, id), body, {
                merge: true,
              })
            : addDoc(usersRef, body));
          return true;
        }
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: () => {
      navigate("../client");
    },
  });

  useQuery({
    queryKey: ["client-details", id],
    queryFn: async () => {
      if (!id) {
        return true;
      }

      try {
        const userDoc = await getDoc(doc(db, constant.COLLECTIONS.USERS, id));

        if (userDoc.exists()) {
          const data = userDoc.data();
          setValues({
            ...values,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            address: data?.address,
            city: data?.city,
            state: data?.state,
            country: data?.country,
            zipCode: data?.zipCode,
            authorizedEmployee: data?.authorizedEmployee,
          });
        } else {
          toastAlert("error", "User does not exist");
        }
      } catch (error) {
        console.log("error", error);
        toastAlert("error", "An error occurred while fetching user data");
      }

      return true;
    },
  });

  const employeeList = async (search) => {
    try {
      let q = query(usersRef, where("role", "==", constant.ROLE.EMPLOYEE));
      const querySnapshot = await getDocs(q);

      const employee = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (search) {
        return employee.filter(
          (i) =>
            i?.email?.includes(search) ||
            i?.firstName?.includes(search) ||
            i?.lastName?.includes(search)
        );
      }

      return employee?.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i.id,
      }));
    } catch (err) {
      console.log("error", err);
      return [];
    }
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "12px",
      padding: "12px",
      width: "100%",
    }),
  };

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>{!!id ? "Edit Client" : "Add Client"}</NavigateBack>
          <Card border="light" bg="light" className="p-4">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.firstName && errors?.firstName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Last Name</label>
                      <input
                        className="inputBox"
                        type="text"
                        name="lastName"
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Last Name"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.lastName && errors?.lastName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt" htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values?.email}
                        disabled={!!id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.email && errors?.email}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Contact No <span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        defaultCountry={constant.DEFAULT_COUNTRY}
                        value={values?.phoneNumber}
                        onChange={(phone) =>
                          setFieldValue("phoneNumber", phone)
                        }
                        onBlur={() => setFieldTouched("phoneNumber", true)}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.phoneNumber && errors?.phoneNumber}
                      </small>
                    </div>{" "}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Address <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={values?.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.address && errors?.address}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.city && errors?.city}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.state && errors?.state}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Country <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Country"
                        name="country"
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.country && errors?.country}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Zipcode <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        placeholder="Enter Zipcode"
                        name="zipCode"
                        value={values?.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={6}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.zipCode && errors?.zipCode}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Authorized Emplopyees </label>
                      <AsyncSelect
                        className="select-box"
                        defaultOptions
                        loadOptions={employeeList}
                        isMulti
                        styles={customStyles}
                        value={values?.authorizedEmployee}
                        onChange={(e) => setFieldValue("authorizedEmployee", e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  {!!id ? "Update" : "Add"}
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
      </section>
      {mutation.isPending && <Loader />}
    </>
  );
};

export default AddClient;
