import { useMutation, useQuery } from "@tanstack/react-query";
import { addDoc, getDocs, orderBy, query, Timestamp, where } from "firebase/firestore";
import { useFormik } from "formik";
import moment from "moment";
import React, { useRef } from "react";
import { Card, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import { eventRef, leaveRef, usersRef } from "../../firebase/FirebaseConfig";
import useDetails from "../../hooks/useDetails";
import { constant } from "../../utils/constants";
import { setTimeFromString } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";
import Swal from "sweetalert2";
import useRole from "../../hooks/useRole";

const AddTimeOff = () => {
  const dateRef = useRef();
  const navigate = useNavigate();
  const details = useDetails();
  const role = useRole();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      date: "",
      fullDay: false,
      startTime: "",
      endTime: "",
      reason: "",
    },
    validationSchema: yup.object().shape({
      date: yup.date().required().label("Time off date"),
      startTime: yup
        .string()
        .required()
        .label("Start time")
        .test(
          "is-greater-than-current",
          "Start time should be later than the current time",
          function (value) {
            const { date } = this.parent;
            const startTime = new Date(date);
            setTimeFromString(startTime, value);
            return startTime >= new Date();
          }
        ),
      endTime: yup
        .string()
        .required()
        .label("End time")
        .test(
          "is-greater-than-startTime",
          "End time should be later than start time",
          function (value) {
            const { date, startTime } = this.parent;
            if (!startTime) {
              return true;
            }
            const start = new Date(date);
            const end = new Date(date);
            setTimeFromString(start, startTime);
            setTimeFromString(end, value);
            return end > start;
          }
        ),
      reason: yup.string().required().label("Reason").trim().min(2),
    }),
    onSubmit: async (values) => {
      let currentUserId = values?.employeeId || ""
      if (role == constant.ROLE.EMPLOYEE) {

        const userQuery = query(usersRef, where("uid", "==", details.uid));
        const userSnapshot = await getDocs(userQuery);
        const currentUserDoc = userSnapshot.docs[0];

        if (!currentUserDoc) {
          toastAlert("error", "User not found");
          return [];
        }
        currentUserId = currentUserDoc.id;
      }
      let leaveStatus = constant.LEAVE_STATUS.PENDING
      if(role == constant.ROLE.ADMIN) leaveStatus = constant.LEAVE_STATUS.APPROVED

      const start = new Date(values?.date);
      setTimeFromString(start, values?.startTime);
      const end = new Date(values?.date);
      setTimeFromString(end, values?.endTime);
      let body = {
        ...values,
        status: leaveStatus,
        employeeId: currentUserId,
        createdAt: Timestamp.now(),
        startDateTime: start,
        endDateTime: end,
      };
      mutation.mutate(body);
    },
  });

  const { data: employeeList } = useQuery({
    queryKey: ["employee-list", role],
    queryFn: async () => {
      try {
        if (role == constant.ROLE.ADMIN) {

          let q = query(
            usersRef,
            where("role", "==", constant.ROLE.EMPLOYEE),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(q);

          const employee = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return employee;
        }
        return []
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        const leaveStart = new Date(body.startDateTime);
        const leaveEnd = new Date(body.endDateTime);

        const startOfLeaveDay = new Date(body.startDateTime);
        startOfLeaveDay.setHours(0, 0, 0, 0);
        const endOfLeaveDay = new Date(body.endDateTime);
        endOfLeaveDay.setHours(23, 59, 59, 999);

        const leaveQuery = query(
          leaveRef,
          where("employeeId", "==", body.employeeId),
          where("startDateTime", "<=", endOfLeaveDay),
          where("endDateTime", ">=", startOfLeaveDay)
        );
        const leaveSnapshot = await getDocs(leaveQuery);

        if (!leaveSnapshot.empty) {
          Swal.fire({
            title: "Already applied for leave!",
            text: `You already have a leave applied on ${moment(
              leaveStart
            ).format("ll")}.`,
            icon: "warning",
            confirmButtonColor: "#0d1227",
          });
          return false;
        }

        const q = query(
          eventRef,
          where("employeeId", "==", body.employeeId),
          where("startTime", "<=", leaveEnd),
          where("endTime", ">=", leaveStart),
          where("startTime", ">=", new Date())
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          Swal.fire({
            title: "Conflict Found!",
            text: `You have a schedule between ${moment(leaveStart).format(
              "ll"
            )} (${moment(leaveStart).format("LT")} - ${moment(leaveEnd).format(
              "LT"
            )}), please contact admin.`,
            icon: "warning",
            confirmButtonColor: "#0d1227",
          });
          return false;
        }

        await addDoc(leaveRef, body);
        return true;
      } catch (err) {
        console.log("err", err);
        throw err;
      }
    },
    onSuccess: () => {
      navigate("../time-off");
    },
  });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>Add Time Off Request</NavigateBack>
          <Card border="light" bg="light" className="p-4 w-50 mx-auto">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox ">
                <div className="row ">

                  {role && role === constant.ROLE.ADMIN &&
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="labelTxt">Employee</label>
                        <select
                          className="inputBox"
                          value={values?.employeeId}
                          name="employeeId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select</option>
                          {employeeList &&
                            employeeList?.map((i, index) => (
                              <option
                                key={index}
                                value={i.id}
                              >{`${i?.firstName} ${i?.lastName}`}</option>
                            ))}
                        </select>

                        <small className="text-danger requiredTxt">
                          {touched.employeeId && errors.employeeId}
                        </small>
                      </div>
                    </div>}

                  <div className="col-md-12 mb-3">
                    <div className="form-group position-relative">
                      <label className="labelTxt">
                        Time Off Date <span className="text-danger">*</span>
                      </label>
                      <img
                        src={images.calendar}
                        className="calanderIcon"
                        alt="calendarImg"
                        role="button"
                        onClick={() => dateRef.current.setFocus()}
                      />

                      <DatePicker
                        selected={values?.date}
                        onChange={(date) => setFieldValue("date", date)}
                        onBlur={() => setFieldTouched("date", true)}
                        className="inputBox"
                        minDate={tomorrow}
                        ref={dateRef}
                      />
                    </div>
                    <small className="text-danger requiredTxt">
                      {touched.date && errors.date}
                    </small>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Check
                      type="checkbox"
                      id="custome-time"
                      label="Full day off"
                      name="fullDay"
                      value={values?.fullDay}
                      checked={values?.fullDay}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          setFieldValue("startTime", "00:00");
                          setFieldValue("endTime", "23:59");
                          setFieldTouched("startTime", false);
                          setFieldTouched("endTime", false);
                        } else {
                          setFieldValue("startTime", "");
                          setFieldTouched("startTime", false);
                          setFieldValue("endTime", "");
                          setFieldTouched("endTime", false);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Start Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="startTime"
                        className="timePicker inputBox"
                        disabled={values?.fullDay}
                        value={values?.startTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.startTime && errors.startTime}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        End Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="endTime"
                        className="timePicker inputBox"
                        disabled={values?.fullDay}
                        value={values?.endTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.endTime && errors.endTime}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows={3}
                        name="reason"
                        className="inputBox"
                        value={values?.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.reason && errors.reason}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  Add
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
      </section>
      {mutation.isPending && <Loader />}
    </>
  );
};

export default AddTimeOff;
